import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import HeaderUser from "../components/Header/HeaderUser";
import Sheet from "../components/Sheet/Sheet";
import Title from "../components/Title/Title";
import Icon from "../components/Icon/Icon";
import Image from "../components/Image/Image";

import ManLaptop from "../assets/man_with_laptop.png";
import GiftBox from "../assets/gift_box.png";
import PatientFooter from "../features/PatientFooter";
import { useNavigateCustom } from "../hooks/base/usePageTransitionCustom";
import { PATIENT } from "../constants/pagePaths";
import SpButton from "../components/Button/SpButton";
import { useRealmApp } from "../contexts/RealmApp";
import { resetUserRegisterInput } from "../hooks/input/useUserRegisterInput";

const USA022 = () => {
  const navigate = useNavigateCustom();
  const appContext = useRealmApp();

  // 自分が会員になる
  const handleMember = () => {
    navigate(PATIENT.USA004);
  };

  // 誰かを招待する
  const handleInvitation = () => {
    navigate(PATIENT.USA025);
  };

  // データのクリア
  const resetDataInputs = () => {
    resetUserRegisterInput();
  };

  // ログイン画面へ
  const handleLogout = () => {
    void appContext.logOut();
    resetDataInputs();
  };

  return (
    <div className="admin-area admin-area_no-header">
      <HeaderUser withRightControl={false} />
      <div className="admin-inner area-with-header-footer">
        <LayoutBox
          fullWidth
          justify="center"
          align="center"
          gap="5x"
          direction="column"
        >
          <Title
            padding="16px 108px 24px 108px"
            background="default"
            header="新規会員登録"
            withButton={false}
            borderBottom="primary"
            minWidth="1088px"
          />
          <Sheet padding="0" minWidth="1088px">
            <LayoutBox
              direction="column"
              gap="3x"
              justify="center"
              align="center"
              width="440px"
            >
              <Sheet
                type="card"
                padding="16px 8px 16px 16px"
                spPadding={false}
                minWidth="343px"
                pointer
                onClick={handleMember}
              >
                <LayoutBox justify="center" align="center" gap="1x" fullWidth>
                  <LayoutBox
                    direction="column"
                    gap="1x"
                    align="center"
                    justify="center"
                    fullWidth
                  >
                    <LayoutBox>
                      <Image
                        url={ManLaptop}
                        type="framed"
                        height="64px"
                        width="64px"
                      />
                    </LayoutBox>

                    <Text size="xl" color="neutralBlack" lineHeight="150%" bold>
                      自分が会員になる
                    </Text>
                  </LayoutBox>

                  <Icon
                    type="outlined"
                    icon="expand_circle_right"
                    size="medium"
                    className="shortcut__arrow-icon"
                    color="primary"
                  />
                </LayoutBox>
              </Sheet>
              <Sheet
                type="card"
                padding="16px 8px 16px 16px"
                spPadding={false}
                minWidth="343px"
                pointer
                onClick={handleInvitation}
              >
                <LayoutBox justify="center" align="center" gap="1x" fullWidth>
                  <LayoutBox
                    direction="column"
                    gap="1x"
                    align="center"
                    justify="center"
                    fullWidth
                  >
                    <LayoutBox>
                      <Image
                        url={GiftBox}
                        type="framed"
                        height="64px"
                        width="64px"
                      />
                    </LayoutBox>

                    <Text size="xl" color="neutralBlack" lineHeight="150%" bold>
                      だれかを招待する
                    </Text>
                  </LayoutBox>

                  <Icon
                    type="outlined"
                    icon="expand_circle_right"
                    size="medium"
                    className="shortcut__arrow-icon"
                    color="primary"
                  />
                </LayoutBox>
              </Sheet>
            </LayoutBox>
          </Sheet>
          <LayoutBox
            direction="column"
            gap="3x"
            justify="center"
            align="center"
            width="440px"
          >
            {" "}
            <SpButton
              size="medium"
              width="100%"
              onClick={() => {
                handleLogout();
              }}
            >
              ログイン画面へ
            </SpButton>
          </LayoutBox>

          <div />
        </LayoutBox>
      </div>
      <PatientFooter />
    </div>
  );
};

export default USA022;
