import React, { useEffect } from "react";
import Divider from "../Divider/Divider";
import Icon from "../Icon/Icon";
import LayoutBox from "../LayoutBox/LayoutBox";
import Popover from "../Popover/Popover";
import UserIcon from "../../assets/user.svg";
import Text from "../Text/Text";

export type AccountMenuProps = {
  onClickProfile: () => void;
  onClickChangeEmail: () => void;
  onClickPassword: () => void;
  onClickReceipt: () => void;
  onClickInvitedAccount: () => void;
  onClickLogout: () => void;
  userName: string;
  isPasswordMenu?: boolean;
  // changeEmp
  isInvitationMenu?: boolean;
  hasInvitationUrls?: boolean;
  onClickTop: () => void;
};

const AccountMenu = ({
  onClickProfile,
  onClickChangeEmail,
  onClickPassword,
  onClickReceipt,
  onClickInvitedAccount,
  onClickLogout,
  userName,
  isPasswordMenu = false,
  // changeEmp
  isInvitationMenu = false,
  hasInvitationUrls = false,
  onClickTop,
}: AccountMenuProps) => {
  const activatorRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleOpen = (isOpen: boolean) => {
    setIsOpen(isOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsOpen(false);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div ref={activatorRef} onClick={() => toggleOpen(!isOpen)}>
        <button type="button" className="header__btn header__btn-user">
          <div className="header__btn-user-container">
            <div className="header__btn-user__icon">
              <img src={UserIcon} alt="user_icon" />
            </div>
            <div className="header__btn-user-text-wrapper">
              <span>{userName}</span>
              <span className="header__btn-user-courtesy">様</span>
            </div>
            <Icon icon="arrow_drop_down" />
          </div>
        </button>
      </div>
      <Popover
        open={isOpen}
        activatorRef={activatorRef}
        width={221}
        direction="underRight"
        forceDirection
        withShadow
        toggleOpen={toggleOpen}
      >
        <div className="menulist">
          <div
            className="menulist-item--settings"
            onClick={() => {
              setIsOpen(false);
              onClickProfile();
            }}
          >
            <LayoutBox gap="1/2x" align="center">
              <Icon icon="person" size="medium" className="icon--person" />
              <Text lineHeight="2" size="xl">
                {" "}
                会員情報
              </Text>
            </LayoutBox>
          </div>
          <Divider white margin="4px" />
          <div
            className="menulist-item--change-email"
            onClick={() => {
              setIsOpen(false);
              onClickChangeEmail();
            }}
          >
            <LayoutBox gap="1/2x" align="center">
              <Icon
                icon="alternate_email"
                size="medium"
                className="icon--mail"
              />
              <Text lineHeight="2" size="xl">
                {" "}
                メールアドレス変更
              </Text>
            </LayoutBox>
          </div>
          {!isPasswordMenu && (
            <>
              <Divider white margin="4px" />
              <div
                className="menulist-item--settings"
                onClick={() => {
                  setIsOpen(false);
                  onClickPassword();
                }}
              >
                <LayoutBox gap="1/2x" align="center">
                  <Icon icon="lock" size="medium" className="icon--lock" />
                  <Text lineHeight="2" size="xl">
                    {" "}
                    パスワード変更
                  </Text>
                </LayoutBox>
              </div>
            </>
          )}
          <Divider white margin="4px" />
          <div
            className="menulist-item--settings"
            onClick={() => {
              setIsOpen(false);
              onClickReceipt();
            }}
          >
            <LayoutBox gap="1/2x" align="center">
              <Icon
                icon="description"
                size="medium"
                className="icon--description"
              />
              <Text lineHeight="2" size="xl">
                {" "}
                領収書
              </Text>
            </LayoutBox>
          </div>

          {isInvitationMenu && !hasInvitationUrls && (
            <>
              <Divider white margin="4px" />
              <div
                className="menulist-item--change-email"
                onClick={() => {
                  setIsOpen(false);
                  onClickInvitedAccount();
                }}
              >
                <LayoutBox gap="1/2x" align="center">
                  <Icon icon="redeem" size="medium" className="icon--mail" />
                  <Text lineHeight="2" size="xl">
                    招待したアカウント
                  </Text>
                </LayoutBox>
              </div>
            </>
          )}
          {isInvitationMenu && hasInvitationUrls && (
            <>
              <Divider white margin="4px" />
              <div
                className="menulist-item--change-email"
                onClick={() => {
                  setIsOpen(false);
                  onClickTop();
                }}
              >
                <LayoutBox gap="1/2x" align="center">
                  <Icon icon="redo" size="medium" className="icon--mail" />
                  <Text lineHeight="2" size="xl">
                    会員トップ
                  </Text>
                </LayoutBox>
              </div>
            </>
          )}

          <Divider darkColor margin="10px" />
          <div
            className="menulist-item--logout"
            onClick={() => {
              setIsOpen(false);
              // FIXME: 別タブでヘルプ表示
              window.open("https://dementia365.com/");
            }}
          >
            <LayoutBox gap="1/2x" align="center">
              <Icon icon="help" size="medium" className="icon--help" />
              <Text lineHeight="2" size="xl">
                使い方マニュアル
              </Text>
            </LayoutBox>
          </div>

          <Divider darkColor margin="10px" />
          <div
            className="menulist-item--logout"
            onClick={() => {
              setIsOpen(false);
              onClickLogout();
            }}
          >
            <LayoutBox gap="1/2x" align="center">
              <Icon icon="logout" size="medium" className="icon--logout" />
              <Text lineHeight="2" size="xl" color="red">
                ログアウト
              </Text>
            </LayoutBox>
          </div>
        </div>
      </Popover>
    </>
  );
};

export default AccountMenu;

// changeEmp
AccountMenu.defaultProps = {
  isPasswordMenu: false,
  isInvitationMenu: false,
  hasInvitationUrls: false,
};
