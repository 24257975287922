import React, { useEffect } from "react";
import SpButton from "../components/Button/SpButton";
import HeaderUser from "../components/Header/HeaderUser";
import Input from "../components/Input/Input";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Sheet from "../components/Sheet/Sheet";
import Text from "../components/Text/Text";
import Title from "../components/Title/Title";
import PatientFooter from "../features/PatientFooter";
import useEmailInput, {
  setEmailError,
  setEmail,
  setReEmail,
  checkExistingEmailInputError,
  setInitEmailInput,
} from "../hooks/input/useEmailInput";
import { PATIENT } from "../constants/pagePaths";
import {
  useNavigateWithBack,
  useNavigateWithUrl,
} from "../hooks/base/usePageTransitionCustom";

import { useCheckErrorThrowError } from "../utils/checkError";
import useUsg017DbActions from "../hooks/pages/USG017/useUsg017DbActions";
import { useRealmApp } from "../contexts/RealmApp";

const USG017 = () => {
  const realmAppContext = useRealmApp();
  const [{ email, reEmail }] = useEmailInput();
  const [id, setId] = React.useState("");
  const { actionError, handleUpdate, existingEmailError } =
    useUsg017DbActions();
  const navigate = useNavigateWithUrl();

  useCheckErrorThrowError([actionError, existingEmailError]);

  useEffect(() => {
    setEmail(realmAppContext.currentUser?.customData.email as string);
    setId(realmAppContext.currentUser?.customData._id as string);
  }, [
    realmAppContext.currentUser?.customData.email,
    realmAppContext.currentUser?.customData._id,
  ]);

  const handleOnSubmit = async () => {
    if (email.value !== reEmail.value) {
      setEmailError();

      return;
    }
    const isSuccess = await handleUpdate(id, email.value); // 非同期処理の完了を待つ

    if (!isSuccess) return;

    navigate(PATIENT.USG018);
    window.location.reload(); // ヘッダーを無理やり更新
  };

  // メールアドレス入力初期化
  useEffect(() => {
    setInitEmailInput();
  }, []);

  // 閉じる、キャンセルボタン押下
  const handleGoBack = useNavigateWithBack(PATIENT.USB001);

  return (
    <div className="admin-area user-body-sp-full-height">
      <LayoutBox direction="column" fullWidth>
        <HeaderUser withRightControl={false} />
        <div className="admin-inner area-with-header-footer">
          <Title
            padding="16px 108px 24px 108px"
            background="default"
            header="メールアドレス変更"
            borderBottom="primary"
            onClickBack={handleGoBack}
          />
          <Sheet padding="40px 0" className="util-sp-py-24">
            <LayoutBox
              direction="column"
              justify="center"
              align="center"
              minWidth="440px"
              className="util-sp-full-width"
            >
              <Sheet type="card" padding="16px 16px 32px 16px">
                <LayoutBox direction="column" gap="2x" fullWidth>
                  <LayoutBox gap="1x" direction="column" fullWidth>
                    <Text bold size="xl" color="neutralBlack" lineHeight="150%">
                      現在のメールアドレス
                    </Text>
                    <Text size="xl" color="neutralBlack" lineHeight="150%">
                      {realmAppContext.currentUser?.customData.email as string}
                    </Text>
                  </LayoutBox>
                  <LayoutBox gap="1x" direction="column" fullWidth>
                    <Text bold size="xl" color="neutralBlack" lineHeight="150%">
                      新しいメールアドレス
                    </Text>
                    <Input
                      width="100%"
                      value={email.value}
                      onChange={(value) => setEmail(value)}
                      error={email.isError}
                    />
                    {[email.validationMessage].map((errorText, index) => (
                      <div
                        key={`caption-massage-${index}`}
                        className="sp-form-set__caption-massage--error"
                      >
                        {errorText}
                      </div>
                    ))}
                  </LayoutBox>
                  <LayoutBox gap="1x" direction="column" fullWidth>
                    <Text bold size="xl" color="neutralBlack" lineHeight="150%">
                      新しいメールアドレス（確認用）
                    </Text>
                    <Input
                      width="100%"
                      value={reEmail.value}
                      onChange={(value) => setReEmail(value)}
                      error={reEmail.isError}
                    />
                    {[reEmail.validationMessage].map((errorText, index) => (
                      <div
                        key={`caption-massage-${index}`}
                        className="sp-form-set__caption-massage--error"
                      >
                        {errorText}
                      </div>
                    ))}
                  </LayoutBox>
                  <SpButton
                    width="100%"
                    size="medium"
                    disabled={checkExistingEmailInputError()}
                    onClick={() => {
                      void handleOnSubmit();
                    }}
                  >
                    変更を保存する
                  </SpButton>
                </LayoutBox>
              </Sheet>
            </LayoutBox>
          </Sheet>
        </div>
      </LayoutBox>
      <PatientFooter />
    </div>
  );
};

export default USG017;
