import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import HeaderUser from "../components/Header/HeaderUser";
import Sheet from "../components/Sheet/Sheet";
import Title from "../components/Title/Title";
import ProgressBar from "../components/Loading/ProgressBar";
import Badge from "../components/Badge/Badge";
import Divider from "../components/Divider/Divider";
import MenuNavigation, {
  SELECTED_TAB_NUM,
} from "../components/MenuNavigation/MenuNavigation";
import useUsc004Actions from "../hooks/pages/USC004/useUsc004DBActions";
import { PATIENT } from "../constants/pagePaths";
import { useCheckErrorThrowError } from "../utils/checkError";
import PatientFooter from "../features/PatientFooter";
import { NOUKNOW_SCORE_TEXT } from "../constants/common";
import useUsc004Conditions, {
  setSelectedDate,
} from "../hooks/pages/USC004/useUsc004Conditions";
import Dropdown from "../components/Dropdown/Dropdown";
import FormSet from "../components/FormSet/FormSet";

const USC004 = () => {
  const [{ selectedDate }] = useUsc004Conditions();
  const { result, error, finishedDateArray } = useUsc004Actions();
  const navigate = useNavigate();

  // エラー処理
  useCheckErrorThrowError([error]);

  // セレクトボックス 受検日
  const handleChangeDate = (value: string) => {
    setSelectedDate(value);
  };

  // テスト・結果Top画面に遷移
  const handleClickBack = useCallback(
    () => navigate(PATIENT.USC026),
    [navigate],
  );

  return (
    <div className="admin-area">
      <HeaderUser />
      <div className="admin-inner area-with-header-footer">
        <MenuNavigation
          minWidth="1076px"
          selectedTab={SELECTED_TAB_NUM.examination}
        />
        <Title
          padding="16px 108px 24px 108px"
          background="default"
          header="結果"
          borderBottom="primary"
          onClickBack={handleClickBack}
          minWidth="1076px"
        />
        <Sheet padding="40px 108px" minWidth="1076px">
          <LayoutBox direction="column" gap="5x" minWidth="1076px">
            <LayoutBox
              direction="column"
              justify="center"
              gap="3x"
              minWidth="1076px"
            >
              <LayoutBox direction="column" justify="center" fullWidth>
                <LayoutBox justify="between" align="center" fullWidth>
                  <Text size="2xl" bold>
                    のうKNOW®
                  </Text>
                </LayoutBox>
                <Divider orange margin="0" borderWidth="3px" />
              </LayoutBox>
              <FormSet label="受検日" vertical>
                <Dropdown
                  items={finishedDateArray}
                  width="195px"
                  value={selectedDate}
                  onChange={handleChangeDate}
                />
              </FormSet>
              <Sheet
                padding="24px 16px"
                type="card"
                color="white"
                withBorderRadius
              >
                <LayoutBox
                  justify="center"
                  align="center"
                  direction="column"
                  gap="3x"
                  fullWidth
                >
                  <LayoutBox
                    gap="2x"
                    direction="column"
                    maxWidth="305px"
                    fullWidth
                  >
                    <LayoutBox gap="1/2x" direction="column" fullWidth>
                      <LayoutBox align="center" justify="between" fullWidth>
                        <Text size="large">集中力スコア</Text>
                        <LayoutBox gap="1x" align="center">
                          <Text size="2xl" bold>
                            {result?.bpi1_score}
                          </Text>
                          <Badge
                            color={
                              result?.bpi1_rank === "A"
                                ? "blue"
                                : result?.bpi1_rank === "B"
                                ? "warning"
                                : "red"
                            }
                            size="large"
                          >
                            {result?.bpi1_rank}
                          </Badge>
                        </LayoutBox>
                      </LayoutBox>
                      <ProgressBar
                        width="305px"
                        biggerBar
                        percentage={Number(result?.bpi1_score | 0)}
                        barColor={
                          result?.bpi1_rank === "A"
                            ? "primary"
                            : result?.bpi1_rank === "B"
                            ? "warning"
                            : "error"
                        }
                      />
                      <Text
                        size="large"
                        bold
                        color={
                          result?.bpi1_rank === "A"
                            ? "blue"
                            : result?.bpi1_rank === "B"
                            ? "warning"
                            : "error"
                        }
                      >
                        {NOUKNOW_SCORE_TEXT[
                          result?.bpi1_rank as keyof typeof NOUKNOW_SCORE_TEXT
                        ] ?? ""}
                      </Text>
                    </LayoutBox>
                    <LayoutBox direction="column" fullWidth>
                      <LayoutBox align="center" justify="between" fullWidth>
                        <Text size="large">記憶力スコア</Text>
                        <LayoutBox gap="1x" align="center">
                          <Text size="2xl" bold>
                            {result?.bpi2_score}
                          </Text>
                          <Badge
                            color={
                              result?.bpi2_rank === "A"
                                ? "blue"
                                : result?.bpi2_rank === "B"
                                ? "warning"
                                : "red"
                            }
                            size="large"
                          >
                            {result?.bpi2_rank}
                          </Badge>
                        </LayoutBox>
                      </LayoutBox>
                      <ProgressBar
                        width="305px"
                        biggerBar
                        percentage={Number(result?.bpi2_score | 0)}
                        barColor={
                          result?.bpi2_rank === "A"
                            ? "primary"
                            : result?.bpi2_rank === "B"
                            ? "warning"
                            : "error"
                        }
                      />
                      <Text
                        size="large"
                        bold
                        color={
                          result?.bpi2_rank === "A"
                            ? "blue"
                            : result?.bpi2_rank === "B"
                            ? "warning"
                            : "error"
                        }
                      >
                        {NOUKNOW_SCORE_TEXT[
                          result?.bpi2_rank as keyof typeof NOUKNOW_SCORE_TEXT
                        ] ?? ""}
                      </Text>
                    </LayoutBox>
                  </LayoutBox>
                  <LayoutBox
                    gap="2x"
                    direction="column"
                    maxWidth="900px"
                    fullWidth
                  >
                    <Sheet type="gray-sp" borderRadius="8px">
                      A：正常な状態です（20.0〜50.0）&#12288;B：ボーダーライン（15.0〜19.9）&#12288;C：維持向上のための活動を取り⼊れましょう（0〜14.9）
                    </Sheet>
                    <Text>
                      ※「のうKNOW」は医療機器ではなく、医師などの医療関係者による診察および診断に代わるものではありません。
                    </Text>
                  </LayoutBox>
                </LayoutBox>
              </Sheet>
            </LayoutBox>
          </LayoutBox>
        </Sheet>
        <div className="util-mb-72" />
      </div>
      <PatientFooter />
    </div>
  );
};

export default USC004;
