/* eslint-disable no-irregular-whitespace */
import { toHalfWidth } from "./utils";

export type ValidationResultType<T> = {
  value: T;
  validationMessage: string;
  isError: boolean;
};
export const INIT_VALIDATION_RESULT = {
  validationMessage: "",
  isError: false,
};

// バリデーションタイプ
export enum VALIDATION_TYPE {
  ID_REQUIRED,
  PASSWORD_REQUIRED,
  TEL_REQUIRED,
  TEL_OPTIONAL,
  NAME_REQUIRED,
  NAME_OPTIONAL,
  REPORT_TEXT_OPTIONAL,
  REMARKS_OPTIONAL,
  VIDEO_TITLE_REQUIRED,
  URL_REQUIRED,
  VIDEO_DESCRIPTION_REQUIRED,
  CAST_NOTES_OPTIONAL,
  NEWS_TITLE_REQUIRED,
  NEWS_TITLE_OPTIONAL,
  NEWS_DETAILS_REQUIRED,
  CAMPAIGN_OPTIONAL,
  SEARCH_TEXT_OPTIONAL,
  CODE_REQUIRED,
  PRODUCT_NAME_REQUIRED,
  PRODUCT_NAME_OPTIONAL,
  PRODUCT_DESCRIPTION_REQUIRED,
  ORDER_OPTIONAL,
  ITEM_NAME_REQUIRED,
  ITEM_CODE_REQUIRED,
  ITEM_DESCRIPTION_OPTIONAL,
  SKU_NAME_REQUIRED,
  SKU_CODE_REQUIRED,
  PRICE_REQUIRED,
  STOCK_REQUIRED,
  OPTION_OPTIONAL,
  USER_ID_REQUIRED,
  USER_NAME_REQUIRED,
  USER_NAME_KANA_REQUIRED,
  EMAIL_REQUIRED,
  HOSPITAL_NAME_REQUIRED,
  ADDRESS_REQUIRED,
  ZIPCODE_REQUIRED,
  CAMPAIGN_CODE,
}

// バリデーションのチェック項目設定タイプ
type ValidationSettingType = {
  isRequired: boolean;
  regexPattern: RegExp;
  minCharCount: number;
  maxCharCount: number;
  validationMessage: string;
};

// バリデーションのチェック項目設定を作成
const createValidationSetting = (
  isRequired: boolean,
  regexPattern: RegExp,
  minCharCount: number,
  maxCharCount: number,
  validationMessage: string,
): ValidationSettingType => ({
  isRequired,
  regexPattern,
  minCharCount,
  maxCharCount,
  validationMessage,
});

// バリデーション設定一覧
const validationSettings = {
  [VALIDATION_TYPE.ID_REQUIRED]: createValidationSetting(
    true,
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    1,
    64,
    "有効なメールアドレスを入力してください。",
  ),
  [VALIDATION_TYPE.PASSWORD_REQUIRED]: createValidationSetting(
    true,
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[!-/:-@[-{-~])[^\s]+$/,
    8,
    72,
    "小文字、大文字、数字を組み合わせて入力してください。",
  ),
  [VALIDATION_TYPE.TEL_REQUIRED]: createValidationSetting(
    true,
    /^\d+$/,
    1,
    15,
    "半角数字（ハイフン無し）で電話番号を入力してください。",
  ),
  [VALIDATION_TYPE.TEL_OPTIONAL]: createValidationSetting(
    false,
    /^\d+$/,
    1,
    15,
    "半角数字（ハイフン無し）で電話番号を入力してください。",
  ),
  [VALIDATION_TYPE.NAME_REQUIRED]: createValidationSetting(
    true,
    /^[ぁ-んァ-ン一-龯々ーa-zA-Z0-9 　]+([ぁ-んァ-ン一-龯々ーa-zA-Z0-9・]*[ぁ-んァ-ン一-龯々ーa-zA-Z0-9 　])?$/,
    1,
    50,
    "名前はひらがな、カタカナ、漢字、全角記号、半角英字、半角スペース、ドット（・）で入力してください。",
  ),
  [VALIDATION_TYPE.NAME_OPTIONAL]: createValidationSetting(
    false,
    /^[ぁ-んァ-ン一-龯々ーa-zA-Z0-9 　]+([ぁ-んァ-ン一-龯々ーa-zA-Z0-9・]*[ぁ-んァ-ン一-龯々ーa-zA-Z0-9 　])?$/,
    1,
    50,
    "名前はひらがな、カタカナ、漢字、全角記号、半角英字、半角スペース、ドット（・）で入力してください。",
  ),
  [VALIDATION_TYPE.REPORT_TEXT_OPTIONAL]: createValidationSetting(
    false,
    /^[ぁ-んァ-ン一-龯々ーa-zA-Z0-9 　]+([ぁ-んァ-ン一-龯々ーa-zA-Z0-9・]*[ぁ-んァ-ン一-龯々ーa-zA-Z0-9 　])?$/,
    1,
    128,
    "不正な文字が含まれています。",
  ),
  [VALIDATION_TYPE.REMARKS_OPTIONAL]: createValidationSetting(
    false,
    /^[ぁ-んァ-ン一-龯々ーa-zA-Z0-9 　]+([ぁ-んァ-ン一-龯々ーa-zA-Z0-9・]*[ぁ-んァ-ン一-龯々ーa-zA-Z0-9 　])?$/,
    1,
    128,
    "不正な文字が含まれています。",
  ),
  [VALIDATION_TYPE.VIDEO_TITLE_REQUIRED]: createValidationSetting(
    true,
    /^[ぁ-んァ-ン一-龯々ーa-zA-Z0-9 　]+([ぁ-んァ-ン一-龯々ーa-zA-Z0-9・]*[ぁ-んァ-ン一-龯々ーa-zA-Z0-9 　])?$/,
    1,
    128,
    "不正な文字が含まれています。",
  ),
  [VALIDATION_TYPE.URL_REQUIRED]: createValidationSetting(
    true,
    /^(https?):\/\/[^\s/$.?#].[^\s]*$/i,
    1,
    70,
    "有効なURLを入力してください。",
  ),
  [VALIDATION_TYPE.VIDEO_DESCRIPTION_REQUIRED]: createValidationSetting(
    true,
    /^[ぁ-んァ-ン一-龯々ーa-zA-Z0-9 　]+([ぁ-んァ-ン一-龯々ーa-zA-Z0-9・]*[ぁ-んァ-ン一-龯々ーa-zA-Z0-9 　])?$/,
    1,
    128,
    "不正な文字が含まれています。",
  ),
  [VALIDATION_TYPE.CAST_NOTES_OPTIONAL]: createValidationSetting(
    false,
    /^[ぁ-んァ-ン一-龯々ーa-zA-Z0-9 　]+([ぁ-んァ-ン一-龯々ーa-zA-Z0-9・]*[ぁ-んァ-ン一-龯々ーa-zA-Z0-9 　])?$/,
    1,
    128,
    "不正な文字が含まれています。",
  ),
  [VALIDATION_TYPE.NEWS_TITLE_REQUIRED]: createValidationSetting(
    true,
    /^[ぁ-んァ-ン一-龯々ーa-zA-Z0-9 　]+([ぁ-んァ-ン一-龯々ーa-zA-Z0-9・]*[ぁ-んァ-ン一-龯々ーa-zA-Z0-9 　])?$/,
    1,
    128,
    "不正な文字が含まれています。",
  ),
  [VALIDATION_TYPE.NEWS_TITLE_OPTIONAL]: createValidationSetting(
    false,
    /^[ぁ-んァ-ン一-龯々ーa-zA-Z0-9 　]+([ぁ-んァ-ン一-龯々ーa-zA-Z0-9・]*[ぁ-んァ-ン一-龯々ーa-zA-Z0-9 　])?$/,
    1,
    128,
    "不正な文字が含まれています。",
  ),
  [VALIDATION_TYPE.NEWS_DETAILS_REQUIRED]: createValidationSetting(
    true,
    /^[ぁ-んァ-ン一-龯々ーa-zA-Z0-9 　]+([ぁ-んァ-ン一-龯々ーa-zA-Z0-9・]*[ぁ-んァ-ン一-龯々ーa-zA-Z0-9 　])?$/,
    1,
    128,
    "不正な文字が含まれています。",
  ),
  [VALIDATION_TYPE.CAMPAIGN_OPTIONAL]: createValidationSetting(
    false,
    /^[ぁ-んァ-ン一-龯々ーa-zA-Z0-9 　]+([ぁ-んァ-ン一-龯々ーa-zA-Z0-9・]*[ぁ-んァ-ン一-龯々ーa-zA-Z0-9 　])?$/,
    1,
    128,
    "不正な文字が含まれています。",
  ),
  [VALIDATION_TYPE.SEARCH_TEXT_OPTIONAL]: createValidationSetting(
    false,
    /^[ぁ-んァ-ン一-龯々ーa-zA-Z0-9 　]+([ぁ-んァ-ン一-龯々ーa-zA-Z0-9・]*[ぁ-んァ-ン一-龯々ーa-zA-Z0-9 　])?$/,
    1,
    128,
    "不正な文字が含まれています。",
  ),
  [VALIDATION_TYPE.CODE_REQUIRED]: createValidationSetting(
    true,
    /^[ぁ-んァ-ン一-龯々ーa-zA-Z0-9 　]+([ぁ-んァ-ン一-龯々ーa-zA-Z0-9・]*[ぁ-んァ-ン一-龯々ーa-zA-Z0-9 　])?$/,
    1,
    8,
    "有効なコードを入力してください。",
  ),
  [VALIDATION_TYPE.PRODUCT_NAME_REQUIRED]: createValidationSetting(
    true,
    /^[ぁ-んァ-ン一-龯々ーa-zA-Z0-9 　]+([ぁ-んァ-ン一-龯々ーa-zA-Z0-9・]*[ぁ-んァ-ン一-龯々ーa-zA-Z0-9 　])?$/,
    1,
    128,
    "不正な文字が含まれています。",
  ),
  [VALIDATION_TYPE.PRODUCT_NAME_OPTIONAL]: createValidationSetting(
    false,
    /^[ぁ-んァ-ン一-龯々ーa-zA-Z0-9 　]+([ぁ-んァ-ン一-龯々ーa-zA-Z0-9・]*[ぁ-んァ-ン一-龯々ーa-zA-Z0-9 　])?$/,
    1,
    128,
    "不正な文字が含まれています。",
  ),
  [VALIDATION_TYPE.PRODUCT_DESCRIPTION_REQUIRED]: createValidationSetting(
    true,
    /^[ぁ-んァ-ン一-龯々ーa-zA-Z0-9 　]+([ぁ-んァ-ン一-龯々ーa-zA-Z0-9・]*[ぁ-んァ-ン一-龯々ーa-zA-Z0-9 　])?$/,
    1,
    128,
    "不正な文字が含まれています。",
  ),
  [VALIDATION_TYPE.ORDER_OPTIONAL]: createValidationSetting(
    false,
    /^[ぁ-んァ-ン一-龯々ーa-zA-Z0-9 　]+([ぁ-んァ-ン一-龯々ーa-zA-Z0-9・]*[ぁ-んァ-ン一-龯々ーa-zA-Z0-9 　])?$/,
    1,
    128,
    "不正な文字が含まれています。",
  ),
  [VALIDATION_TYPE.ITEM_NAME_REQUIRED]: createValidationSetting(
    true,
    /^[ぁ-んァ-ン一-龯々ーa-zA-Z0-9 　]+([ぁ-んァ-ン一-龯々ーa-zA-Z0-9・]*[ぁ-んァ-ン一-龯々ーa-zA-Z0-9 　])?$/,
    1,
    128,
    "不正な文字が含まれています。",
  ),
  [VALIDATION_TYPE.ITEM_CODE_REQUIRED]: createValidationSetting(
    true,
    /^[ぁ-んァ-ン一-龯々ーa-zA-Z0-9 　]+([ぁ-んァ-ン一-龯々ーa-zA-Z0-9・]*[ぁ-んァ-ン一-龯々ーa-zA-Z0-9 　])?$/,
    1,
    8,
    "有効なコードを入力してください。",
  ),
  [VALIDATION_TYPE.ITEM_DESCRIPTION_OPTIONAL]: createValidationSetting(
    false,
    /^[ぁ-んァ-ン一-龯々ーa-zA-Z0-9 　]+([ぁ-んァ-ン一-龯々ーa-zA-Z0-9・]*[ぁ-んァ-ン一-龯々ーa-zA-Z0-9 　])?$/,
    1,
    128,
    "不正な文字が含まれています。",
  ),
  [VALIDATION_TYPE.SKU_NAME_REQUIRED]: createValidationSetting(
    true,
    /^[ぁ-んァ-ン一-龯々ーa-zA-Z0-9 　]+([ぁ-んァ-ン一-龯々ーa-zA-Z0-9・]*[ぁ-んァ-ン一-龯々ーa-zA-Z0-9 　])?$/,
    1,
    128,
    "不正な文字が含まれています。",
  ),
  [VALIDATION_TYPE.SKU_CODE_REQUIRED]: createValidationSetting(
    true,
    /^[ぁ-んァ-ン一-龯々ーa-zA-Z0-9 　]+([ぁ-んァ-ン一-龯々ーa-zA-Z0-9・]*[ぁ-んァ-ン一-龯々ーa-zA-Z0-9 　])?$/,
    1,
    8,
    "有効なSKUコードを入力してください。",
  ),
  [VALIDATION_TYPE.PRICE_REQUIRED]: createValidationSetting(
    true,
    /^(?!0\d)(?:\d{1,3}(?:,\d{3})*|\d+)$/,
    1,
    15,
    "有効な金額を入力してください。",
  ),
  [VALIDATION_TYPE.STOCK_REQUIRED]: createValidationSetting(
    true,
    /^(?:[1-9]|[1-9][0-9]+)$/,
    1,
    4,
    "有効な個数を入力してください。",
  ),
  [VALIDATION_TYPE.OPTION_OPTIONAL]: createValidationSetting(
    false,
    /^[ぁ-んァ-ン一-龯々ーa-zA-Z0-9 　]+([ぁ-んァ-ン一-龯々ーa-zA-Z0-9・]*[ぁ-んァ-ン一-龯々ーa-zA-Z0-9 　])?$/,
    1,
    128,
    "不正な文字が含まれています。",
  ),
  [VALIDATION_TYPE.USER_ID_REQUIRED]: createValidationSetting(
    true,
    /^[ぁ-んァ-ン一-龯々ーa-zA-Z0-9 　]+([ぁ-んァ-ン一-龯々ーa-zA-Z0-9・]*[ぁ-んァ-ン一-龯々ーa-zA-Z0-9 　])?$/,
    1,
    128,
    "有効なIDを入力してください。",
  ),
  [VALIDATION_TYPE.USER_NAME_REQUIRED]: createValidationSetting(
    true,
    /^[ぁ-んァ-ン一-龯々ーa-zA-Z0-9 　-]+([ぁ-んァ-ン一-龯々ーa-zA-Z0-9・ヶ-]*)?$/,
    1,
    128,
    "有効なユーザー名を入力してください。",
  ),
  [VALIDATION_TYPE.USER_NAME_KANA_REQUIRED]: createValidationSetting(
    true,
    /^[ァ-ンー・]+$/,
    1,
    128,
    "全角カナで入力してください。",
  ),
  [VALIDATION_TYPE.EMAIL_REQUIRED]: createValidationSetting(
    true,
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    1,
    64,
    "有効なメールアドレスを入力してください。",
  ),
  [VALIDATION_TYPE.HOSPITAL_NAME_REQUIRED]: createValidationSetting(
    true,
    /^[ぁ-んァ-ン一-龯々ーa-zA-Z0-9 　-]+([ぁ-んァ-ン一-龯々ーa-zA-Z0-9・-]*[ぁ-んァ-ン一-龯々ーa-zA-Z0-9 　-])?$/,
    1,
    128,
    "有効な病院名を入力してください。",
  ),
  [VALIDATION_TYPE.ADDRESS_REQUIRED]: createValidationSetting(
    true,
    /^[ぁ-んァ-ン一-龯々ーa-zA-Z0-9 　-]+([ぁ-んァ-ン一-龯々ーa-zA-Z0-9・ヶ-]*)?$/,
    1,
    128,
    "有効な住所を入力してください。",
  ),
  [VALIDATION_TYPE.ZIPCODE_REQUIRED]: createValidationSetting(
    true,
    /^\d{7}$/,
    7,
    7,
    "郵便番号は7桁の数字を入力してください。",
  ),
  [VALIDATION_TYPE.CAMPAIGN_CODE]: createValidationSetting(
    true,
    /^[a-zA-Z0-9_-]+$/,
    1,
    20,
    "小文字、大文字、数字を組み合わせて入力してください。",
  ),
};

// バリデーションメッセージ取得
const getValidationMessage = (
  isEmpty: boolean,
  isInvalidChar: boolean,
  isOverCharCount: boolean,
  currentValidationSetting: ValidationSettingType,
): string => {
  const { minCharCount, maxCharCount, validationMessage } =
    currentValidationSetting;
  if (isEmpty) {
    return "入力が空です。";
  }
  if (isInvalidChar) {
    return validationMessage;
  }
  if (isOverCharCount) {
    return `${minCharCount}～${maxCharCount}文字で入力してください。`;
  }

  return "";
};

// 共通バリデーションチェック エラー状態とバリデーションメッセージを返す
const commonValidation = (
  value: string,
  type: VALIDATION_TYPE,
): { isError: boolean; validationMessage: string } => {
  const currentValidationSetting = validationSettings[type];
  const { isRequired, minCharCount, maxCharCount, regexPattern } =
    currentValidationSetting;

  // 任意入力で0文字の場合はバリデーションチェックしない
  const isOptional = !isRequired;
  const isLengthZero = value.length === 0;
  if (isOptional && isLengthZero) {
    return {
      isError: false,
      validationMessage: "",
    };
  }

  // 全角文字を半角に変換
  const halfWidthValue = toHalfWidth(value);

  // 必須項目の場合必須チェック
  const isEmpty = isRequired ? !halfWidthValue : false;
  // 文字数チェック
  const isOverCharCount =
    halfWidthValue.length < minCharCount ||
    halfWidthValue.length > maxCharCount;
  // 入力文字チェック
  const isInvalidChar = !regexPattern.test(halfWidthValue);
  // エラー状態
  const isError = isEmpty || isInvalidChar || isOverCharCount;
  // バリデーションメッセージ取得
  const validationMessage = getValidationMessage(
    isEmpty,
    isInvalidChar,
    isOverCharCount,
    currentValidationSetting,
  );

  return {
    isError,
    validationMessage,
  };
};

export default commonValidation;
