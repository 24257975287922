// Containerコンポーネント(機能や状態などロジックを記載)
// 循環参照の影響で、このコンポーネントで全てのロジック情報を記述

import { useLocation } from "react-router-dom";
import { INVITATION, PATIENT } from "../../constants/pagePaths";
import { useNavigateWithUrl } from "../../hooks/base/usePageTransitionCustom";

import useHeaderActions from "../../hooks/component/Header/useHeaderActions";
import HeaderUserComponent, {
  HeaderUserComponentProps,
} from "./HeaderUserComponent";

import { useRealmApp } from "../../contexts/RealmApp";
import useUsj001Dialog, {
  openUsj001Dialog,
} from "../../hooks/pages/USJ001/useUsj001Dialog";
import USJ001 from "../../pages/US-J001";
import usePatientInvitationsFetch from "../../hooks/common/usePatientInvitationsFetch";
import usePatientInfoFetch from "../../hooks/common/usePatientInfoFetch";
import { PatientTypeID } from "../../constants/common";
import { resetUserRegisterInput } from "../../hooks/input/useUserRegisterInput";

type HeaderUserProps = {
  withRightControl?: boolean;
  backgroundColor?: HeaderUserComponentProps["backgroundColor"];
};

const HeaderUser = ({
  withRightControl = true,
  backgroundColor = "white",
}: HeaderUserProps) => {
  const { newNotificationCount } = useHeaderActions();
  const { patientInvitations } = usePatientInvitationsFetch("all");
  const { patientInfo } = usePatientInfoFetch();

  const navigateWithUrl = useNavigateWithUrl();
  const [isMessageListDialog] = useUsj001Dialog();

  const appContext = useRealmApp();
  const userNameSei = appContext.currentUser?.customData.name_sei as string;
  const userNameMei = appContext.currentUser?.customData.name_mei as string;
  const userName = `${userNameSei} ${userNameMei}`;

  // パスワード変更を表示するか
  const isPasswordMenu = !!(
    patientInfo?.line_id ||
    patientInfo?.google_id ||
    patientInfo?.yahoo_id
  );

  // 招待したアカウントメニューを表示するか
  const isInvitationMenu =
    patientInvitations.length > 0 &&
    patientInfo?.patient_type === PatientTypeID.MEMBER;

  // 招待者関連URLかどうか
  // メニューの招待したアカウント・会員トップを出しわける
  const location = useLocation();
  const currentUrl = location.pathname;
  const invitationUrls = [
    INVITATION.USA029,
    INVITATION.USA030,
    INVITATION.USA031,
    INVITATION.USA033,
  ];
  const hasInvitationUrls = invitationUrls.some((path) =>
    currentUrl.includes(path),
  );

  // データのクリア
  const resetDataInputs = () => {
    resetUserRegisterInput();
  };

  const handleLogout = () => {
    void appContext.logOut();
    resetDataInputs();
  };

  // 会員情報に遷移
  const goToMemberInfoPage = () => {
    navigateWithUrl(PATIENT.USG005);
  };

  // メールアドレス変更に遷移
  const goToEmailInfoPage = () => {
    navigateWithUrl(PATIENT.USG017);
  };

  // パスワード変更に遷移
  const goToPasswordInfoPage = () => {
    navigateWithUrl(PATIENT.USG015);
  };

  // 招待したアカウントに遷移
  const goToInvitedAccountsPage = () => {
    navigateWithUrl(INVITATION.USA029);
  };

  // 領収書一覧に遷移
  const goToReceiptListPage = () => {
    navigateWithUrl(PATIENT.USG012);
  };

  // 会員トップに遷移
  const goToTopPage = () => {
    navigateWithUrl(PATIENT.USB001);
  };

  return (
    <>
      <HeaderUserComponent
        newNotificationCount={newNotificationCount}
        handleShowProfile={goToMemberInfoPage}
        handleShowEmail={goToEmailInfoPage}
        handleShowPassword={goToPasswordInfoPage}
        handleShowReceiptList={goToReceiptListPage}
        handleInvite={goToInvitedAccountsPage}
        handleLogout={handleLogout}
        withRightControl={withRightControl}
        backgroundColor={backgroundColor}
        userName={userName}
        handleMessage={() => openUsj001Dialog()}
        isPasswordMenu={isPasswordMenu}
        isInvitationMenu={isInvitationMenu}
        handleTop={goToTopPage}
        hasInvitationUrls={hasInvitationUrls}
      />
      {isMessageListDialog && <USJ001 />}
    </>
  );
};

HeaderUser.defaultProps = {
  withRightControl: true,
  backgroundColor: "white",
};

export default HeaderUser;
