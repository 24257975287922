import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import HeaderUser from "../components/Header/HeaderUser";
import Sheet from "../components/Sheet/Sheet";
import Icon from "../components/Icon/Icon";
import SpButton from "../components/Button/SpButton";
import { useNavigateWithUrl } from "../hooks/base/usePageTransitionCustom";
import { PATIENT } from "../constants/pagePaths";
import PatientFooter from "../features/PatientFooter";
import { setPath } from "../hooks/common/usePath";

const USA044 = () => {
  const navigate = useNavigateWithUrl();

  const onNextButtonClicked = () => {
    setPath(PATIENT.USA044);
    navigate(PATIENT.USA027);
  };

  return (
    <div className="admin-area user-body-sp-full-height">
      <LayoutBox direction="column" fullWidth>
        <HeaderUser withRightControl={false} />
        <div className="admin-inner admin-area_no-header">
          <Sheet padding="40px" className="util-sp-py-24">
            <LayoutBox direction="column" maxWidth="440px" width="440px">
              <Sheet type="card" padding="24px 16px" className="util-sp-pt-16">
                <LayoutBox gap="3x" direction="column" align="center" fullWidth>
                  <LayoutBox direction="column" gap="2x" align="center">
                    <Icon color="#388e3c" icon="check_circle" size="xl" />
                    <Text
                      bold
                      size="2xl"
                      color="neutralUserBlack"
                      lineHeight="150%"
                      textAlign="center"
                    >
                      登録が完了しました
                    </Text>
                  </LayoutBox>
                  <SpButton
                    type="primary"
                    width="100%"
                    size="medium"
                    withArrowRight
                    onClick={onNextButtonClicked}
                  >
                    招待メールを送る
                  </SpButton>
                </LayoutBox>
              </Sheet>
            </LayoutBox>
          </Sheet>
        </div>
      </LayoutBox>
      <PatientFooter />
    </div>
  );
};

export default USA044;
