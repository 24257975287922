import { useEffect } from "react";
import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import Sheet from "../components/Sheet/Sheet";
import Title from "../components/Title/Title";
import Divider from "../components/Divider/Divider";
import FormSet from "../components/FormSet/FormSet";
import SpFormSet from "../components/FormSet/SpFormSet";
import SpButton from "../components/Button/SpButton";
import HeaderUser from "../components/Header/HeaderUser";
import useUserInvitationInput, {
  checkUserInvitationInputError,
} from "../hooks/input/useUserInvitationInput";
import {
  useCurrentLocationState,
  useNavigateWithUrl,
} from "../hooks/base/usePageTransitionCustom";
import { redirectToInvalidAccessPage } from "../contexts/CustomErrorBoundary";
import { PATIENT } from "../constants/pagePaths";
import useUsa026DBActions from "../hooks/pages/USA026/useUsa026DBActions";
import PatientFooter from "../features/PatientFooter";

const USA026 = () => {
  const { updateUser } = useUsa026DBActions();
  const [
    {
      nameSei,
      nameMei,
      nameSeiKana,
      nameMeiKana,
      gender,
      birthYear,
      birthMonth,
      birthDay,
      tel,
      // selectedNotice,
    },
  ] = useUserInvitationInput();
  const navigate = useNavigateWithUrl();
  const onNextButtonClicked = () => {
    void (async () => {
      const isSuccess = await updateUser();
      if (!isSuccess) return;

      navigate(PATIENT.USA044);
      window.location.reload(); // ヘッダーを無理やり更新
    })();
  };

  const handleGoBack = () => navigate(PATIENT.USA025);

  // URLの直打ち対応
  const transitionURL = useCurrentLocationState();
  useEffect(() => {
    // ページアクセス不正の場合
    const isInvalidRedirect = ![PATIENT.USA025, PATIENT.USA027].includes(
      transitionURL.from as PATIENT,
    );
    // 入力項目が不正の場合
    const isInvalidInput = checkUserInvitationInputError();
    const shouldRedirect = isInvalidRedirect || isInvalidInput;
    if (shouldRedirect) redirectToInvalidAccessPage();
  }, [transitionURL]);

  return (
    <div className="admin-area user-body-sp-full-height">
      <div className="admin-inner area-with-header-footer">
        <HeaderUser withRightControl={false} />
        <Title
          padding="16px 108px 24px 108px"
          background="default"
          header="お客様情報の確認"
          borderBottom="primary"
          withButton={false}
        />
        <div className="util-overflow-x">
          <Sheet padding="40px 0">
            <LayoutBox gap="3x" direction="column" fullWidth>
              <LayoutBox
                direction="column"
                justify="center"
                align="center"
                gap="2x"
                fullWidth
                minWidth="1064px"
              >
                <Sheet
                  type="card"
                  padding="16px 32px 32px 32px"
                  width="100%"
                  maxWidth="1064px"
                >
                  <LayoutBox direction="column" gap="3x" fullWidth>
                    <LayoutBox direction="column" fullWidth>
                      <Text size="2xl" bold>
                        会員情報
                      </Text>
                      <Divider borderWidth="3px" margin="0" />
                    </LayoutBox>
                    <LayoutBox direction="column" gap="1x" fullWidth>
                      <SpFormSet
                        label="お名前"
                        base
                        labelSize="medium"
                        labelWidth="200px"
                        spToColumnAndFullWidth
                        labelGap="large"
                      >
                        <LayoutBox
                          direction="column"
                          className="util-sp-full-width"
                        >
                          <LayoutBox gap="1x" fullWidth justify="between">
                            <FormSet label="姓" vertical width="196px">
                              <LayoutBox
                                direction="column"
                                gap="1/2x"
                                fullWidth
                              >
                                <Text size="xl">{nameSei.value}</Text>
                                <Divider margin="0" darkColor />
                              </LayoutBox>
                            </FormSet>
                            <FormSet label="名" vertical width="196px">
                              <LayoutBox
                                direction="column"
                                gap="1/2x"
                                fullWidth
                              >
                                <Text size="xl">{nameMei.value}</Text>
                                <Divider margin="0" darkColor />
                              </LayoutBox>
                            </FormSet>
                          </LayoutBox>
                          <LayoutBox gap="1x" fullWidth justify="between">
                            <FormSet label="セイ" vertical width="196px">
                              <LayoutBox
                                direction="column"
                                gap="1/2x"
                                fullWidth
                              >
                                <Text size="xl">{nameSeiKana.value}</Text>
                                <Divider margin="0" darkColor />
                              </LayoutBox>
                            </FormSet>
                            <FormSet label="メイ" vertical width="196px">
                              <LayoutBox
                                direction="column"
                                gap="1/2x"
                                fullWidth
                              >
                                <Text size="xl">{nameMeiKana.value}</Text>
                                <Divider margin="0" darkColor />
                              </LayoutBox>
                            </FormSet>
                          </LayoutBox>
                        </LayoutBox>
                      </SpFormSet>
                      <SpFormSet
                        label="性別"
                        base
                        labelSize="medium"
                        labelWidth="200px"
                        spToColumnAndFullWidth
                        labelGap="large"
                      >
                        <LayoutBox
                          direction="column"
                          gap="1/2x"
                          minWidth="400px"
                          className="util-sp-full-width"
                          justify="end"
                          fullHeight
                        >
                          <Text size="xl">
                            {gender.value === "1" ? "男性" : "女性"}
                          </Text>
                          <Divider margin="0" darkColor />
                        </LayoutBox>
                      </SpFormSet>
                      <SpFormSet
                        label="生年月日"
                        base
                        labelSize="medium"
                        labelWidth="200px"
                        spToColumnAndFullWidth
                        labelGap="large"
                      >
                        <LayoutBox
                          direction="column"
                          gap="1/2x"
                          minWidth="400px"
                          className="util-sp-full-width"
                          justify="end"
                          fullHeight
                        >
                          <Text size="xl">{`${birthYear.value}年${birthMonth.value}月${birthDay.value}日`}</Text>
                          <Divider margin="0" darkColor />
                        </LayoutBox>
                      </SpFormSet>
                      <SpFormSet
                        label="電話番号"
                        base
                        labelSize="medium"
                        labelWidth="200px"
                        spToColumnAndFullWidth
                        labelGap="large"
                      >
                        <LayoutBox
                          direction="column"
                          gap="1/2x"
                          minWidth="400px"
                          className="util-sp-full-width"
                          justify="end"
                          fullHeight
                        >
                          <Text size="xl">{tel.value}</Text>
                          <Divider margin="0" darkColor />
                        </LayoutBox>
                      </SpFormSet>
                    </LayoutBox>
                  </LayoutBox>
                </Sheet>

                {/* FIXME: 仕様未決定のため、一旦非表示 */}
                {/* <Sheet
                  type="card"
                  padding="16px 32px 32px 32px"
                  maxWidth="1064px"
                >
                  <LayoutBox direction="column" gap="3x" fullWidth>
                    <LayoutBox direction="column" fullWidth>
                      <Text size="2xl" bold>
                        メール通知
                      </Text>
                      <Divider borderWidth="3px" margin="0" />
                    </LayoutBox>
                    <Text size="xl">
                      {selectedNotice.value === "1" ? "希望する" : "希望しない"}
                    </Text>
                  </LayoutBox>
                </Sheet> */}

                <LayoutBox
                  minWidth="1064px"
                  justify="between"
                  gap="2x"
                  className="util-sp-full-width"
                  wrapReverse
                >
                  <SpButton
                    type="secondary"
                    width="400px"
                    size="medium"
                    mobileFullWidth
                    iconSize="large"
                    withArrowLeft
                    onClick={handleGoBack}
                  >
                    戻る
                  </SpButton>
                  <SpButton
                    type="primary"
                    width="400px"
                    size="medium"
                    withArrowRight
                    mobileFullWidth
                    onClick={onNextButtonClicked}
                  >
                    登録
                  </SpButton>
                </LayoutBox>
              </LayoutBox>
            </LayoutBox>
          </Sheet>
        </div>
      </div>
      <PatientFooter />
    </div>
  );
};

export default USA026;
