/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Collection,
  JST,
  ItemsSkusStatusID,
  JST_OFFSET_HOURS,
  ItemsStatusID,
  OrderStatusID,
  CampaignsStatusID,
  EmbeddedPageID,
  USER_PLAN_CATEGORY,
  ModalityID,
  HospitalStatusID,
  PatientMessageRelationshipStatusID,
  PatientNouKnowStatusID,
  MriStatusID,
  PetStatusID,
  YOSE_STATUS,
  NotificationStatusID,
  INTERVIEW_STATUS,
  PatientBrainCheckStatusID,
  SALES_STATUS,
  UserStatusID,
  CampaignCouponsStatusID,
  PATIENT_INVITATIONS_STATUS,
} from "../constants/common";
import { redirectToInvalidFunctionPage } from "../contexts/CustomErrorBoundary";

// MongoDB Query作成処理
type AddStage = { [key: string]: unknown };
type SortStage = { [key: string]: 1 | -1 };
type MatchStage = { [key: string]: unknown };
type ProjectStage = { [key: string]: unknown };
type GroupStage = { _id: string; [key: string]: unknown };
type LookupStage = {
  from: string;
  localField?: string;
  foreignField?: string;
  as: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let?: Record<string, any>;
  pipeline?: PipelineStage[];
};
type UnwindStage = {
  path: string;
  preserveNullAndEmptyArrays?: boolean;
  includeArrayIndex?: string;
};

type LookUp = {
  [collection: string]: {
    [lookupField: string]: LookupStage;
  };
};

type PipelineStage =
  | { $addFields: AddStage }
  | { $skip: number }
  | { $limit: number }
  | { $count: string }
  | { $sort: SortStage }
  | { $match: MatchStage }
  | { $group: GroupStage }
  | { $project: ProjectStage }
  | { $lookup: LookupStage }
  | { $unwind: UnwindStage | string }
  | { $facet: FacetStage };
type FacetStage = { [key: string]: PipelineStage[] };

// 予約などのFrom,Toの時間の差分、1時間で固定
const TIME_DIFF_MS = 3600000;

// Dateフィールドの値を指定されたフォーマットに変換
const _formatDateField = (
  fieldValue: string,
  format: string,
  addTimeMs: string | number = 0,
) => {
  // 時間を指定されていた場合は加算
  if (addTimeMs) {
    return {
      $dateToString: {
        format,
        date: { $add: ["$$fieldValue", addTimeMs] },
        timezone: JST,
      },
    };
  }

  return {
    $dateToString: {
      format,
      date: fieldValue,
      timezone: JST,
    },
  };
};

// usersテーブルのスラッシュ区切りのフルネーム取得条件作成
const _createUsersFullNameSlashStage = () => ({
  $concat: [
    "$name_sei",
    "$name_mei",
    " / ",
    "$name_sei_kana",
    "$name_mei_kana",
  ],
});

// スラッシュ区切りの誕生日取得条件作成
const _createBirthdaySlashStage = (fieldPath?: string) => {
  const prefix = fieldPath ? `${fieldPath}.` : "";

  const zeroPad = (field: string) => ({
    $cond: [
      { $lt: [field, 10] },
      { $concat: ["0", { $toString: field }] },
      { $toString: field },
    ],
  });

  return {
    $concat: [
      { $toString: `${prefix}birth_year` },
      "/",
      zeroPad(`${prefix}birth_month`),
      "/",
      zeroPad(`${prefix}birth_day`),
    ],
  };
};

// 性別を表示用に変換する関数
const _convertGenderId = (gender: string) => {
  switch (gender) {
    case "male":
      return "1";
    case "female":
      return "2";
    default:
      return "$patient_info.gender";
  }
};

// Date値から曜日を取得
const _getDayOfWeekSwitch = (fieldValue: string) => ({
  $switch: {
    branches: [
      { case: { $eq: [{ $dayOfWeek: fieldValue }, 1] }, then: "日" },
      { case: { $eq: [{ $dayOfWeek: fieldValue }, 2] }, then: "月" },
      { case: { $eq: [{ $dayOfWeek: fieldValue }, 3] }, then: "火" },
      { case: { $eq: [{ $dayOfWeek: fieldValue }, 4] }, then: "水" },
      { case: { $eq: [{ $dayOfWeek: fieldValue }, 5] }, then: "木" },
      { case: { $eq: [{ $dayOfWeek: fieldValue }, 6] }, then: "金" },
      { case: { $eq: [{ $dayOfWeek: fieldValue }, 7] }, then: "土" },
    ],
    default: "",
  },
});

// 年月日日時の取得条件作成
const _createDateToStringStage = (
  fieldName: string,
  format = "%Y/%m/%d %H:%M",
) => _formatDateField(`$${fieldName}`, format);

// 「年月日(曜日)日時from ~ to」の取得条件作成 DB値が空の場合「‐」を表示
const _createDateToStringWithWeekStage = (
  fieldName: string,
  timeDiffMs: string | number = TIME_DIFF_MS,
) => ({
  $let: {
    vars: {
      fieldValue: { $ifNull: [`$${fieldName}`, "-"] },
    },
    in: {
      $cond: [
        { $eq: ["$$fieldValue", "-"] },
        // DB値が空の場合「‐」
        "-",
        // DB値がある場合変換して取得
        {
          $concat: [
            _formatDateField("$$fieldValue", "%Y/%m/%d"),
            "(",
            _getDayOfWeekSwitch("$$fieldValue"),
            ") ",
            _formatDateField("$$fieldValue", "%H:%M"),
            " ～ ",
            _formatDateField("$$fieldValue", "%H:%M", timeDiffMs),
          ],
        },
      ],
    },
  },
});

// スラッシュ区切りのフルネーム取得条件作成
const _createFullNameSlashStage = () => ({
  $concat: [
    "$last_name",
    "$first_name",
    " / ",
    "$last_name_kana",
    "$first_name_kana",
  ],
});

// 半角区切りのフルネーム取得条件作成
const _createFullNameSpaceStage = () => ({
  $concat: ["$patient_info.last_name", " ", "$patient_info.first_name"],
});

const _createSortCondition = (
  sortField: string,
  sortDirection: string,
): SortStage => {
  const sortDirectionNum = sortDirection === "asc" ? 1 : -1;

  return { [sortField]: sortDirectionNum };
};

// ソート条件作成
const _createSortConditions = (
  baseSortField: string, // ソートして値が同じ場合のソート対象
  baseSortDirection: string,
  sortField: string,
  sortDirection: string,
): SortStage => {
  const baseSortCondition = _createSortCondition(
    baseSortField,
    baseSortDirection,
  );
  const sortCondition = _createSortCondition(sortField, sortDirection);

  if (sortField === baseSortField) {
    return sortCondition;
  }

  return {
    ...sortCondition,
    ...baseSortCondition,
  };
};

// 部分一致検索条件作成
const _createPartialMatch = (field: string, value: string | null): MatchStage =>
  value ? { [field]: { $regex: value, $options: "i" } } : {};

// 部分一致検索を複数条件作成
const _createInputConditions = (searchConditions: {
  [field: string]: string | null; // { DBのカラム名: 検索値 }
}): MatchStage =>
  Object.entries(searchConditions).reduce(
    (prevSearchConditions, [field, value]) => ({
      ...prevSearchConditions,
      ..._createPartialMatch(field, value),
    }),
    {},
  );

// ORで部分一致検索を複数条件作成
const _createInputOrConditions = (
  value: string | null,
  fields: string[],
): MatchStage =>
  value
    ? { $or: fields.map((field) => _createPartialMatch(field, value)) }
    : {};

// チェック欄の検索条件作成
const _createCheckConditions = (
  field: string,
  statusArr: number[],
): MatchStage => ({
  [field]: { $in: statusArr },
});

// チェック欄の検索条件(String)作成
const _createCheckStringConditions = (
  field: string,
  statusArr: string[],
): MatchStage => ({
  [field]: { $in: statusArr },
});

// チェック欄のキーなし、空を含む検索条件作成
const _createCheckConditionsIncludeNullOrEmpty = (
  field: string,
  valuesArray: string[],
): MatchStage => {
  if (!valuesArray.length) {
    // valuesArrayが空の場合、存在しないまたは空の配列の条件だけを返す
    return {
      $or: [{ [field]: { $exists: false } }, { [field]: { $eq: [] } }],
    };
  }

  return {
    $or: [
      { [field]: { $exists: false } },
      { [field]: { $eq: [] } },
      _createCheckStringConditions(field, valuesArray),
    ],
  };
};

// 日付の範囲検索条件作成
const _createDateRangeConditions = (
  field: string,
  startDate: Date | undefined,
  endDate: Date | undefined,
): MatchStage => {
  if (startDate && endDate) {
    return {
      [field]: {
        $gte: new Date(startDate.setHours(0, 0, 0, 0)),
        $lte: new Date(endDate.setHours(23, 59, 59, 999)),
      },
    };
  }

  return {};
};

// 年、月から検索条件を作成
const _createOrderDateConditions = (
  year: string | null,
  month: string | null,
  key: string,
): MatchStage => {
  if (year && month) {
    const startDate = new Date(
      Date.UTC(parseInt(year), parseInt(month) - 1, 1, -JST_OFFSET_HOURS, 0, 0),
    );
    const endDate = new Date(
      Date.UTC(parseInt(year), parseInt(month), 0, 14, 59, 59),
    );

    return {
      [key]: {
        $gte: startDate,
        $lte: endDate,
      },
    };
  }

  if (year) {
    const startDate = new Date(
      Date.UTC(parseInt(year), 0, 1, -JST_OFFSET_HOURS, 0, 0),
    );
    const endDate = new Date(Date.UTC(parseInt(year), 11, 31, 14, 59, 59));

    return {
      [key]: {
        $gte: startDate,
        $lte: endDate,
      },
    };
  }

  if (month) {
    // 年がない場合、現在の年で検索
    const currentYear = new Date().getFullYear();
    const startDate = new Date(
      Date.UTC(currentYear, parseInt(month) - 1, 1, -JST_OFFSET_HOURS, 0, 0),
    );
    const endDate = new Date(
      Date.UTC(currentYear, parseInt(month), 0, 14, 59, 59),
    );

    return {
      [key]: {
        $gte: startDate,
        $lte: endDate,
      },
    };
  }

  return {};
};

// 注文ステータス検索条件を作成
const _createOrderStatusConditions = (tabId: string): { status?: number } => {
  const statusMap: { [key: string]: number } = {
    "2": 2, // 支払い完了
    "3": 3, // 出荷済
    "4": 4, // 配送完了
  };

  return statusMap[tabId] ? { status: statusMap[tabId] } : {};
};

// 1ページ分のデータと全体件数条件
const _createSearchAndCountFacet = (
  sortConditions: SortStage,
  skip: number, // 取得開始位置
  limit: number, // 取得個数
  project: ProjectStage,
): FacetStage => ({
  // 1ページ分のデータ
  result: [
    { $sort: sortConditions },
    { $skip: skip },
    { $limit: limit },
    { $project: project },
  ],
  // 全体件数をカウント
  count: [{ $count: "count" }],
});

// 1ページ分のデータと全体件数条件(group毎)
const _createSearchAndCountGroupFacet = (
  sortConditions: SortStage,
  skip: number, // 取得開始位置
  limit: number, // 取得個数
  project: ProjectStage,
  group: GroupStage,
): FacetStage => ({
  // 1ページ分のデータ
  result: [
    { $project: project },
    { $group: group },
    { $sort: sortConditions },
    { $skip: skip },
    { $limit: limit },
  ],
  // 全体件数をカウント
  count: [{ $project: project }, { $group: group }, { $count: "count" }],
});

// DB紐づけ条件 LOOK_UP.[Collection名].[紐づけるCollection名]で定義
const LOOK_UP: LookUp = {
  PATIENTS: {
    PATIENTS_INTERVIEW: {
      from: "patient_interviews",
      localField: "_id",
      foreignField: "patient_id",
      as: "interviews_info",
    },
    PATIENTS_INVITATION_TICKETS: {
      from: "invitation_tickets",
      localField: "invitation_ticket_id",
      foreignField: "_id",
      as: "invitation_tickets_info",
    },
    PATIENTS_NOUKNOW: {
      from: "patient_nouknows",
      localField: "_id",
      foreignField: "patient_id",
      as: "nouknows_info",
    },
    PATIENTS_CART: {
      from: "orders",
      localField: "_id",
      foreignField: "patient_id",
      as: "cart_info",
      pipeline: [
        {
          $match: {
            $expr: {
              $eq: ["$status", OrderStatusID.CART_KEPT],
            },
          },
        },
      ],
    },
    PATIENTS_RECOMMENDATION_ITEMS: {
      from: "items",
      as: "recommendation_items",
      let: { evaluation: "$evaluation" },
      pipeline: [
        {
          $match: {
            $expr: {
              $or: [
                {
                  $in: ["$$evaluation", "$search_contents"],
                },
                {
                  $in: [99, "$search_contents"],
                },
              ],
            },
          },
        },
        {
          $match: {
            $expr: {
              $eq: ["$status", ItemsSkusStatusID.VISIBLE],
            },
          },
        },
        {
          $lookup: {
            from: "skus",
            localField: "_id",
            foreignField: "item_id",
            as: "skus_info",
            pipeline: [
              {
                $match: {
                  $expr: {
                    $eq: ["$status", ItemsSkusStatusID.VISIBLE],
                  },
                },
              },
              {
                $match: {
                  $expr: {
                    $gte: ["$stock", 1],
                  },
                },
              },
              {
                $project: {
                  _id: 1,
                  amount: 1,
                },
              },
              { $sort: { amount: 1 } },
              { $limit: 1 },
            ],
          },
        },
        { $sort: { sort: -1, update_at: -1 } },
        { $match: { "skus_info.0": { $exists: true } } },
        { $limit: 6 },
        {
          $project: {
            _id: 1,
            item_name: 1,
            item_code: 1,
            image_urls: 1,
            skus_info: 1,
          },
        },
      ],
    },
    PATIENTS_ITEMS: {
      from: "items",
      as: "new_items",
      pipeline: [
        {
          $match: {
            $expr: {
              $eq: ["$status", ItemsStatusID.VISIBLE],
            },
          },
        },
        {
          $lookup: {
            from: "skus",
            localField: "_id",
            foreignField: "item_id",
            as: "skus_info",
            pipeline: [
              {
                $match: {
                  $expr: {
                    $eq: ["$status", ItemsSkusStatusID.VISIBLE],
                  },
                },
              },
              {
                $match: {
                  $expr: {
                    $gte: ["$stock", 1],
                  },
                },
              },
              {
                $project: {
                  _id: 1,
                  amount: 1,
                },
              },
              { $sort: { amount: 1 } },
              { $limit: 1 },
            ],
          },
        },
        { $sort: { sort: -1, update_at: -1 } },
        { $match: { "skus_info.0": { $exists: true } } },
        { $limit: 6 },
        {
          $project: {
            _id: 1,
            item_name: 1,
            item_code: 1,
            image_urls: 1,
            skus_info: 1,
            status: 1,
          },
        },
      ],
    },
  },
  NOTIFICATIONS: {
    USERS: {
      from: "users",
      localField: "last_updated_admin_id",
      foreignField: "_id",
      as: "user_info",
    },
  },
  CAMPAIGNS: {
    ADMINS: {
      from: "admins",
      localField: "last_updated_admin_id",
      foreignField: "_id",
      as: "admin_info",
    },
  },
  ITEMS: {
    ITEM_TAGS: {
      from: "item_tags",
      localField: "tag",
      foreignField: "_id",
      as: "item_tags_info",
    },
    OPTION_CONFIGS: {
      from: "option_configs",
      localField: "_id",
      foreignField: "item_id",
      as: "option_configs_info",
    },
    SKUS: {
      from: "skus",
      localField: "_id",
      foreignField: "item_id",
      as: "skus_info",
      pipeline: [
        {
          $match: {
            $expr: {
              $eq: ["$status", ItemsSkusStatusID.VISIBLE],
            },
          },
        },
        {
          $match: {
            $expr: {
              $gte: ["$stock", 1],
            },
          },
        },
        { $sort: { amount: 1 } },
      ],
    },
    // SKUとオプションの情報
    SKUS_OPTION_CONTENTS: {
      from: "skus",
      let: { item_id: "$_id" },
      pipeline: [
        {
          $match: {
            $expr: { $eq: ["$item_id", "$$item_id"] },
          },
        },
        {
          $lookup: {
            from: "option_contents",
            let: { sku_id: "$_id" },
            pipeline: [
              {
                $match: {
                  $expr: { $eq: ["$sku_id", "$$sku_id"] },
                },
              },
            ],
            as: "option_contents_info",
          },
        },
      ],
      as: "skus_info",
    },
  },
  ORDERS: {
    PATIENTS: {
      from: "patients",
      localField: "patient_id",
      foreignField: "_id",
      as: "patient_info",
    },
    TRANSACTIONS: {
      from: "transactions",
      localField: "_id",
      foreignField: "order_id",
      as: "transactions_info",
      pipeline: [
        {
          $lookup: {
            from: "skus",
            localField: "sku_id",
            foreignField: "_id",
            as: "skus_info",
            pipeline: [
              {
                $project: {
                  _id: 1,
                  item_id: 1,
                  image_urls: 1,
                  sku_code: 1,
                  sku_name: 1,
                  amount: 1,
                  stock: 1,
                },
              },
              { $limit: 1 },
            ],
          },
        },
        { $unwind: { path: "$skus_info", preserveNullAndEmptyArrays: true } },
      ],
    },
    USERS: {
      from: "users",
      localField: "patient_id",
      foreignField: "_id",
      as: "user_info",
    },
    SALES: {
      from: "sales",
      localField: "_id",
      foreignField: "order_id",
      as: "sales_info",
      pipeline: [
        { $sort: { payment_date: -1 } },
        { $limit: 1 },
        {
          $project: {
            _id: 1,
            payment_date: 1,
            amount: 1,
          },
        },
      ],
    },
  },
  SKUS: {
    ITEMS: {
      from: "items",
      localField: "item_id",
      foreignField: "_id",
      as: "items_info",
    },
    ITEMS_TAGS: {
      from: "item_tags",
      localField: "items_info.tag",
      foreignField: "_id",
      as: "item_tags_info",
    },
    OPTION_CONTENTS: {
      from: "option_contents",
      localField: "_id",
      foreignField: "sku_id",
      as: "option_contents_info",
    },
    OPTION_CONTENTS_OPTION_CONFIGS: {
      from: "option_contents",
      localField: "option_contents_info.option_config_id",
      foreignField: "_id",
      as: "option_configs_info",
    },
  },
  SALES: {
    ORDERS: {
      from: "orders",
      localField: "order_id",
      foreignField: "_id",
      as: "orders_info",
    },
    ORDERS_PATIENTS: {
      from: "patients",
      localField: "orders_info.patient_id",
      foreignField: "_id",
      as: "patient_info",
    },
    PATIENT_MODALITY_BOOKS: {
      from: "patient_modality_books",
      localField: "patient_modality_book_id",
      foreignField: "_id",
      as: "patient_modality_book_info",
    },
    PATIENT_MODALITY_BOOKS_HOSPITALS: {
      from: "hospitals",
      localField: "patient_modality_book_info.hospital_id",
      foreignField: "_id",
      as: "hospital_info",
    },
    TRANSACTIONS: {
      from: "transactions",
      localField: "order_id",
      foreignField: "order_id",
      as: "transactions_info",
    },
  },
  PATIENT_MODALITY_BOOKS: {
    PATIENTS: {
      from: "patients",
      localField: "patient_id",
      foreignField: "_id",
      as: "patient_info",
    },
    PATIENTS_USERS: {
      from: "users",
      localField: "patient_info._id",
      foreignField: "_id",
      as: "user_info",
    },
    HOSPITALS: {
      from: "hospitals",
      localField: "hospital_id",
      foreignField: "_id",
      as: "hospital_info",
    },
    INSPECTION_FEES: {
      from: "inspection_fees",
      localField: "_id",
      foreignField: "patient_modality_book_id",
      as: "inspection_info",
    },
    USERS: {
      from: "users",
      localField: "patient_id",
      foreignField: "_id",
      as: "user_info",
    },
  },
  USERS: {
    ADMINS: {
      from: "admins",
      localField: "_id",
      foreignField: "_id",
      as: "admin_info",
    },
    PATIENTS: {
      from: "patients",
      localField: "_id",
      foreignField: "_id",
      as: "patient_info",
    },
    PATIENT_NOUKNOWS: {
      from: "patient_nouknows",
      localField: "_id",
      foreignField: "patient_id",
      as: "patient_nouknow_info",
    },
    PATIENT_MODALITY_BOOKS: {
      from: "patient_modality_books",
      localField: "_id",
      foreignField: "patient_id",
      as: "patient_modality_book_info",
    },
    PATIENT_MODALITY_BOOKS_HOSPITALS: {
      from: "hospitals",
      localField: "patient_modality_book_info.hospital_id",
      foreignField: "_id",
      as: "hospital_info",
    },
    PATIENTS_INTERVIEW: {
      from: "patient_interviews",
      localField: "_id",
      foreignField: "patient_id",
      as: "interviews_info",
    },
    PATIENT_SUBSCRIPTIONS: {
      from: "patient_subscriptions",
      localField: "_id",
      foreignField: "patient_id",
      as: "patient_subscriptions_info",
    },
    PATIENT_SUBSCRIPTIONS_SUBSCRIPTION_PLANS: {
      from: "subscription_plans",
      localField: "patient_subscriptions_info.subscription_plan_id",
      foreignField: "_id",
      as: "subscription_plan_info",
    },
    PATIENTS_CART: {
      from: "orders",
      localField: "_id",
      foreignField: "patient_id",
      as: "cart_info",
      pipeline: [
        {
          $match: {
            $expr: {
              $eq: ["$status", OrderStatusID.CART_KEPT],
            },
          },
        },
        {
          $limit: 1,
        },
      ],
    },
    PATIENTS_CAMPAIGN_COUPONS: {
      from: "campaign_coupons",
      localField: "patient_info.campaign_coupon_id",
      foreignField: "_id",
      as: "campaign_coupon_info",
    },
    PATIENT_INVITATIONS: {
      from: "patient_invitations",
      localField: "_id",
      foreignField: "invited_patient_id",
      as: "patient_invitation_info",
    },
  },
  PATIENTS_INTERVIEW: {
    USERS: {
      from: "users",
      localField: "patient_id",
      foreignField: "_id",
      as: "user_info",
    },
    INTERVIEW_FEES: {
      from: "interview_fees",
      localField: "_id",
      foreignField: "patient_interview_id",
      as: "interview_fee_info",
    },
    PATIENTS: {
      from: "patients",
      localField: "patient_id",
      foreignField: "_id",
      as: "patient_info",
    },
  },
  PATIENT_MESSAGE_RELATIONSHIPS: {
    MESSAGES: {
      from: "messages",
      localField: "message_id",
      foreignField: "_id",
      as: "message_info",
    },
  },
  PATIENT_INVITATIONS: {
    USERS: {
      from: "users",
      localField: "invited_patient_id",
      foreignField: "_id",
      as: "invitation_info",
    },
    INVITATIONS_USER: {
      from: "users",
      localField: "parent_patient_id",
      foreignField: "_id",
      as: "user_info",
    },
    PATIENT_SUBSCRIPTIONS: {
      from: "patient_subscriptions",
      localField: "invited_patient_id",
      foreignField: "patient_id",
      as: "subscription_info",
    },
    PATIENT_SUBSCRIPTIONS_SUBSCRIPTION_PLANS: {
      from: "subscription_plans",
      localField: "subscription_info.subscription_plan_id",
      foreignField: "_id",
      as: "subscription_plan_info",
    },
  },
  PATIENT_SUBSCRIPTIONS: {
    USERS: {
      from: "users",
      localField: "patient_id",
      foreignField: "_id",
      as: "user_info",
    },
    SALES: {
      from: "sales",
      localField: "_id",
      foreignField: "patient_subscription_id",
      as: "sales_info",
    },
    SUBSCRIPTION_PLAN: {
      from: "subscription_plans",
      localField: "subscription_plan_id",
      foreignField: "_id",
      as: "subscription_plan_info",
    },
  },
};

// queryで取得したCollectionのカラム 0で非表示 1で表示
const PROJECTS: { [key: string]: ProjectStage } = {
  PATIENTS_INVITATION_TICKETS: {
    status: 1,
    interview_fix_date_start: 1,
    interview_fix_date_end: 1,
    expired_at: "$invitation_tickets_info.expired_at",
    interviewTicketsStatus: "$invitation_tickets_info.status",
    invitation_ticket_id: 1,
    candidate_interview_dates: {
      first: {
        $cond: [
          { $eq: ["$candidate_interview_dates.first", null] },
          "",
          {
            $concat: [
              {
                $dateToString: {
                  format: "%Y/%m/%d %H:%M",
                  date: "$candidate_interview_dates.first",
                  timezone: JST,
                },
              },
              "～",
              {
                $dateToString: {
                  format: "%H:%M",
                  date: {
                    $add: ["$candidate_interview_dates.first", TIME_DIFF_MS],
                  },
                  timezone: JST,
                },
              },
            ],
          },
        ],
      },
      second: {
        $cond: [
          { $eq: ["$candidate_interview_dates.second", null] },
          "",
          {
            $concat: [
              {
                $dateToString: {
                  format: "%Y/%m/%d %H:%M",
                  date: "$candidate_interview_dates.second",
                  timezone: JST,
                },
              },
              "～",
              {
                $dateToString: {
                  format: "%H:%M",
                  date: {
                    $add: ["$candidate_interview_dates.second", TIME_DIFF_MS],
                  },
                  timezone: JST,
                },
              },
            ],
          },
        ],
      },
      third: {
        $cond: [
          { $eq: ["$candidate_interview_dates.third", null] },
          "",
          {
            $concat: [
              {
                $dateToString: {
                  format: "%Y/%m/%d %H:%M",
                  date: "$candidate_interview_dates.third",
                  timezone: JST,
                },
              },
              "～",
              {
                $dateToString: {
                  format: "%H:%M",
                  date: {
                    $add: ["$candidate_interview_dates.third", TIME_DIFF_MS],
                  },
                  timezone: JST,
                },
              },
            ],
          },
        ],
      },
      fourth: {
        $cond: [
          { $eq: ["$candidate_interview_dates.fourth", null] },
          "",
          {
            $concat: [
              {
                $dateToString: {
                  format: "%Y/%m/%d %H:%M",
                  date: "$candidate_interview_dates.fourth",
                  timezone: JST,
                },
              },
              "～",
              {
                $dateToString: {
                  format: "%H:%M",
                  date: {
                    $add: ["$candidate_interview_dates.fourth", TIME_DIFF_MS],
                  },
                  timezone: JST,
                },
              },
            ],
          },
        ],
      },
      fifth: {
        $cond: [
          { $eq: ["$candidate_interview_dates.fifth", null] },
          "",
          {
            $concat: [
              {
                $dateToString: {
                  format: "%Y/%m/%d %H:%M",
                  date: "$candidate_interview_dates.fifth",
                  timezone: JST,
                },
              },
              "～",
              {
                $dateToString: {
                  format: "%H:%M",
                  date: {
                    $add: ["$candidate_interview_dates.fifth", TIME_DIFF_MS],
                  },
                  timezone: JST,
                },
              },
            ],
          },
        ],
      },
    },
  },
  ONE_SETTING: {
    _id: 1,
    delivery_consumption_tax_rate: 1,
    delivery_fee: 1,
    free_delivery_min_amount: 1,
    tax_rounding: 1,
    interview_interval: 1,
    interview_open_am: 1,
    interview_open_pm: 1,
  },
  ONE_PATIENT: {
    _id: 1,
    status: 1,
    full_name_slash: _createUsersFullNameSlashStage(),
    email: 1,
    tel: "$patient_info.tel",
    birth: _createBirthdaySlashStage("$patient_info"),
    gender: "$patient_info.gender",
    evaluation: "$patient_info.evaluation",
    is_enabled_modality: "$patient_info.is_enabled_modality",
    past_diagnosis: "$patient_info.past_diagnosis",
    is_notification: "$patient_info.is_notification",
    patient_type: "$patient_info.patient_type",
    plan_name: "$subscription_plan_info.plan_name",
    price: "$subscription_plan_info.price",
    span: "$subscription_plan_info.span",
    zipcode: "$patient_info.zipcode",
    pref: "$patient_info.pref",
    city: "$patient_info.city",
    town1: "$patient_info.town1",
    town2: "$patient_info.town2",
    name_sei: 1,
    name_mei: 1,
    name_sei_kana: 1,
    name_mei_kana: 1,
    birth_year: "$patient_info.birth_year",
    birth_month: "$patient_info.birth_month",
    birth_day: "$patient_info.birth_day",
    cart_info: 1,
    stripe_subscription_id:
      "$patient_subscriptions_info.stripe_subscription_id",
    campaign_code: "$campaign_coupon_info.campaign_code",
    discount_rate: "$campaign_coupon_info.discount_rate",
    discount_price: "$campaign_coupon_info.discount_price",
    payment_type: "$campaign_coupon_info.payment_type",
    term: "$campaign_coupon_info.term",
    invitation_status: "$patient_invitation_info.status",
    line_id: 1,
    google_id: 1,
    yahoo_id: 1,
  },
  ONE_ORDERS: {
    _id: 1,
    item_num: 1,
    settlement_time: 1,
    delivery_date: 1,
    desired_delivery_date: 1,
    delivery_method: 1,
    delivery_fee: 1,
    transaction_time: 1,
    total_amount: 1,
    total_item_amount: 1,
    total_amount_per_tax: 1,
    sales_info: 1,
    transactions_info: 1,
    cancel_time: 1,
    status: 1,
    order_zipcode: 1,
    order_address: 1,
  },
  ONE_ITEMS: {
    _id: 1,
    item_name: 1,
    item_code: 1,
    item_description: 1,
    image_urls: 1,
    skus_info: 1,
    item_tags_info: 1,
    "option_configs_info.option_title": 1,
    "option_configs_info._id": 1,
  },
  USA004: {
    _id: 1,
    yahoo_id: 1,
    line_id: 1,
    google_id: 1,
    email: 1,
    name_sei: 1,
    name_mei: 1,
    name_sei_kana: 1,
    name_mei_kana: 1,
    zipcode: "$patient_info.zipcode",
    pref: "$patient_info.pref",
    city: "$patient_info.city",
    town1: "$patient_info.town1",
    town2: "$patient_info.town2",
    gender: _convertGenderId("$patient_info.gender"),
    birth_year: "$patient_info.birth_year",
    birth_month: "$patient_info.birth_month",
    birth_day: "$patient_info.birth_day",
    tel: "$patient_info.tel",
    is_notification: "$patient_info.is_notification",
    campaign_code: "$patient_info.campaign_code",
    status: 1,
  },
  USF001: {
    _id: 1,
    zipcode: 1,
    pref: 1,
    city: 1,
    town1: 1,
    town2: 1,
    cart_info: 1,
    recommendation_items: 1,
    new_items: 1,
  },
  USF004: {
    _id: 1,
    item_name: 1,
    item_code: 1,
    image_urls: 1,
    skus_info: 1,
  },
  USF002: {
    _id: 1,
    delivery_date: 1,
    desired_delivery_date: 1,
    settlement_time: 1,
    cancel_time: 1,
    status: 1,
    total_amount: 1,
    sales_info: 1,
    transactions_info: 1,
  },
  USE002: {
    _id: 1,
    vimeo_id: 1,
    yose_title: 1,
    yose_description: 1,
    cast: 1,
    status: 1,
    streaming_date: 1,
    streaming_end_date: 1,
    new_period: 1,
    updated_at: 1,
  },
  USF012: {
    _id: 1,
    transactions_info: 1,
  },
  PATIENT_INTERVIEWS: {
    _id: 1,
    status: 1,
    created_at: 1,
    zoom_link: 1,
    invitation_ticket_id: 1,
    interviewFixDateFromTo: {
      $let: {
        vars: {
          start: { $ifNull: ["$interview_fix_date_start", null] },
          end: { $ifNull: ["$interview_fix_date_end", null] },
        },
        in: {
          $cond: [
            { $or: [{ $eq: ["$$start", null] }, { $eq: ["$$end", null] }] },
            "-",
            {
              $concat: [
                {
                  $dateToString: {
                    format: "%Y/%m/%d %H:%M",
                    date: "$$start",
                    timezone: JST,
                  },
                },
                " ～ ",
                {
                  $dateToString: {
                    format: "%H:%M",
                    date: "$$end",
                    timezone: JST,
                  },
                },
                " (",
                {
                  // ミリ秒を分に変換する処理
                  $toString: {
                    $floor: {
                      $divide: [
                        { $subtract: ["$$end", "$$start"] }, // タイムスタンプの差分を取得
                        1000 * 60, // ミリ秒から分に変換
                      ],
                    },
                  },
                },
                " 分)",
              ],
            },
          ],
        },
      },
    },
  },
  PATIENT_ONLINE_TESTS: {
    _id: 1,
    online_test_url: 1,
    online_test_status: 1,
    created_at: 1,
  },
  PATIENT_NOUKNOWS: {
    _id: 1,
    status: 1,
    created_at: 1,
    finished_at: _createDateToStringStage("finished_at", "%Y/%m/%d"),
    finished_at_date: "$finished_at",
    exam_start: 1,
    exam_end: 1,
    count_of_nouknow: 1,
    nouknow_url: 1,
  },
  PATIENT_MRI: {
    _id: 1,
    mri_status: 1,
    created_at: 1,
    hospital_id: 1,
    hospital_name: "$hospital_info.hospital_name",
    prefecture: "$hospital_info.prefecture",
    fix_book_date_start: 1,
    fix_book_date_end: 1,
    user_reported_at: 1,
    fix_book_date: {
      $concat: [
        _createDateToStringStage("fix_book_date_start"),
        "～",
        _createDateToStringStage("fix_book_date_end", "%H:%M"),
      ],
    },
    next_mri_date: {
      $cond: {
        if: "$fix_book_date_start", // fix_book_date が存在するかチェック
        then: {
          $dateToString: {
            format: "%Y/%m/%d", // 日付を文字列に変換する形式
            date: {
              $dateAdd: {
                startDate: "$fix_book_date_start", // 基準となる日付
                unit: "year", // 単位（年）
                amount: 1, // 加算する量（1年）
              },
            },
          },
        },
        else: null, // fix_book_date が存在しない場合
      },
    },
    candidate_book_dates: {
      first: {
        $cond: [
          { $eq: ["$candidate_book_dates.first", null] },
          "",
          {
            $concat: [
              {
                $dateToString: {
                  format: "%Y/%m/%d %H:%M",
                  date: "$candidate_book_dates.first",
                  timezone: JST,
                },
              },
              "～",
              {
                $dateToString: {
                  format: "%H:%M",
                  date: {
                    $add: ["$candidate_book_dates.first", TIME_DIFF_MS],
                  },
                  timezone: JST,
                },
              },
            ],
          },
        ],
      },
      second: {
        $cond: [
          { $eq: ["$candidate_book_dates.second", null] },
          "",
          {
            $concat: [
              {
                $dateToString: {
                  format: "%Y/%m/%d %H:%M",
                  date: "$candidate_book_dates.second",
                  timezone: JST,
                },
              },
              "～",
              {
                $dateToString: {
                  format: "%H:%M",
                  date: {
                    $add: ["$candidate_book_dates.second", TIME_DIFF_MS],
                  },
                  timezone: JST,
                },
              },
            ],
          },
        ],
      },
      third: {
        $cond: [
          { $eq: ["$candidate_book_dates.third", null] },
          "",
          {
            $concat: [
              {
                $dateToString: {
                  format: "%Y/%m/%d %H:%M",
                  date: "$candidate_book_dates.third",
                  timezone: JST,
                },
              },
              "～",
              {
                $dateToString: {
                  format: "%H:%M",
                  date: {
                    $add: ["$candidate_book_dates.third", TIME_DIFF_MS],
                  },
                  timezone: JST,
                },
              },
            ],
          },
        ],
      },
      fourth: {
        $cond: [
          { $eq: ["$candidate_book_dates.fourth", null] },
          "",
          {
            $concat: [
              {
                $dateToString: {
                  format: "%Y/%m/%d %H:%M",
                  date: "$candidate_book_dates.fourth",
                  timezone: JST,
                },
              },
              "～",
              {
                $dateToString: {
                  format: "%H:%M",
                  date: {
                    $add: ["$candidate_book_dates.fourth", TIME_DIFF_MS],
                  },
                  timezone: JST,
                },
              },
            ],
          },
        ],
      },
      fifth: {
        $cond: [
          { $eq: ["$candidate_book_dates.fifth", null] },
          "",
          {
            $concat: [
              {
                $dateToString: {
                  format: "%Y/%m/%d %H:%M",
                  date: "$candidate_book_dates.fifth",
                  timezone: JST,
                },
              },
              "～",
              {
                $dateToString: {
                  format: "%H:%M",
                  date: {
                    $add: ["$candidate_book_dates.fifth", TIME_DIFF_MS],
                  },
                  timezone: JST,
                },
              },
            ],
          },
        ],
      },
    },
  },
  PATIENT_PET: {
    _id: 1,
    pet_status: 1,
    created_at: 1,
    hospital_id: 1,
    prefecture: "$hospital_info.prefecture",
    hospital_name: "$hospital_info.hospital_name",
    fix_book_date_start: 1,
    fix_book_date_end: 1,
    fix_book_date: {
      $concat: [
        _createDateToStringStage("fix_book_date_start"),
        "～",
        _createDateToStringStage("fix_book_date_end", "%H:%M"),
      ],
    },
    candidate_book_dates: {
      first: {
        $cond: [
          { $eq: ["$candidate_book_dates.first", null] },
          "",
          {
            $concat: [
              {
                $dateToString: {
                  format: "%Y/%m/%d %H:%M",
                  date: "$candidate_book_dates.first",
                  timezone: JST,
                },
              },
              "～",
              {
                $dateToString: {
                  format: "%H:%M",
                  date: {
                    $add: ["$candidate_book_dates.first", TIME_DIFF_MS],
                  },
                  timezone: JST,
                },
              },
            ],
          },
        ],
      },
      second: {
        $cond: [
          { $eq: ["$candidate_book_dates.second", null] },
          "",
          {
            $concat: [
              {
                $dateToString: {
                  format: "%Y/%m/%d %H:%M",
                  date: "$candidate_book_dates.second",
                  timezone: JST,
                },
              },
              "～",
              {
                $dateToString: {
                  format: "%H:%M",
                  date: {
                    $add: ["$candidate_book_dates.second", TIME_DIFF_MS],
                  },
                  timezone: JST,
                },
              },
            ],
          },
        ],
      },
      third: {
        $cond: [
          { $eq: ["$candidate_book_dates.third", null] },
          "",
          {
            $concat: [
              {
                $dateToString: {
                  format: "%Y/%m/%d %H:%M",
                  date: "$candidate_book_dates.third",
                  timezone: JST,
                },
              },
              "～",
              {
                $dateToString: {
                  format: "%H:%M",
                  date: {
                    $add: ["$candidate_book_dates.third", TIME_DIFF_MS],
                  },
                  timezone: JST,
                },
              },
            ],
          },
        ],
      },
      fourth: {
        $cond: [
          { $eq: ["$candidate_book_dates.fourth", null] },
          "",
          {
            $concat: [
              {
                $dateToString: {
                  format: "%Y/%m/%d %H:%M",
                  date: "$candidate_book_dates.fourth",
                  timezone: JST,
                },
              },
              "～",
              {
                $dateToString: {
                  format: "%H:%M",
                  date: {
                    $add: ["$candidate_book_dates.fourth", TIME_DIFF_MS],
                  },
                  timezone: JST,
                },
              },
            ],
          },
        ],
      },
      fifth: {
        $cond: [
          { $eq: ["$candidate_book_dates.fifth", null] },
          "",
          {
            $concat: [
              {
                $dateToString: {
                  format: "%Y/%m/%d %H:%M",
                  date: "$candidate_book_dates.fifth",
                  timezone: JST,
                },
              },
              "～",
              {
                $dateToString: {
                  format: "%H:%M",
                  date: {
                    $add: ["$candidate_book_dates.fifth", TIME_DIFF_MS],
                  },
                  timezone: JST,
                },
              },
            ],
          },
        ],
      },
    },
  },
  MRI_HOSPITALS: {
    _id: 1,
    hospital_name: 1,
    mri_interval: 1,
  },
  PET_HOSPITALS: {
    _id: 1,
    hospital_name: 1,
    service_price: 1,
    pet_interval: 1,
  },
  USA024: {
    _id: 1,
    campaign_code: 1,
    discount_rate: 1,
    discount_price: 1,
    term: 1,
    cancel_period: 1,
    limit: 1,
    payment_span: 1,
  },
  USA024Term: {
    _id: 1,
    campaign_coupon_id: 1,
  },
  USA028: {
    _id: 1,
    email: 1,
    payment_plan: 1,
    code: 1,
  },
  USC001: {
    _id: 1,
    patient_id: 1,
    finished_at: _createDateToStringStage("finished_at", "%Y/%m/%d"),
    bpi1_rank: 1,
    bpi2_rank: 1,
    bpi1_score: 1,
    bpi2_score: 1,
    nouknow_url: 1,
    status: 1,
  },
  USC002: {
    _id: 1,
    patient_id: 1,
    mri_problem: 1,
    mri_status: 1,
    created_at: 1,
    fix_book_date_start: _createDateToStringStage(
      "fix_book_date_start",
      "%Y/%m/%d",
    ),
  },
  USC003: {
    _id: 1,
    patient_id: 1,
    pet_problem: 1,
    pet_status: 1,
    created_at: 1,
    fix_book_date_start: _createDateToStringStage(
      "fix_book_date_start",
      "%Y/%m/%d",
    ),
  },
  USC021: {
    _id: 1,
    patient_id: 1,
    exam_start_date: _createDateToStringStage("exam_start_date", "%Y/%m/%d"),
    status: 1,
  },
  PATIENT_MESSAGE: {
    _id: 1,
    created_at: _createDateToStringStage("created_at"),
    status: 1,
    subject: "$message_info.subject",
    description: "$message_info.description",
    is_head_check: 1,
    headCheckTermsStr: {
      $concat: [
        _createDateToStringStage("head_check_terms.start", "%Y/%m/%d"),
        "～",
        _createDateToStringStage("head_check_terms.end", "%Y/%m/%d"),
      ],
    },
    headCheckTerms: "$head_check_terms",
  },
  NOTIFICATION_TOP: {
    _id: 1,
    title: 1,
    deliveried_at: _createDateToStringStage("deliveried_at"),
  },
  PATIENT_INVITATIONS: {
    _id: 1,
    parent_patient_id: 1,
    invited_patient_id: 1,
    payment_plan: 1,
    email: 1,
    code: 1,
    status: 1,
    name: {
      $concat: ["$invitation_info.name_sei", " ", "$invitation_info.name_mei"],
    },
    subscriptionSpan: "$subscription_plan_info.span",
    subscriptionPrice: "$subscription_plan_info.price",
    stripeSubscriptionId: "$subscription_info.stripe_subscription_id",
  },
  USA029: {
    _id: 1,
    patient_type: 1,
  },
  USA033: {
    _id: 1,
    full_name: "$user_info.full_name",
    amount: "$sales_info.amount",
    year: _createDateToStringStage("sales_info.payment_date", "%Y"),
    month: _createDateToStringStage("sales_info.payment_date", "%m"),
  },
  ALL_ORDER_YEAR: {
    _id: 0,
    finished_at: 1,
  },
  ALL_ORDER_YEAR_BRAIN: {
    _id: 0,
    finished_at: "$exam_start_date",
  },
  ALL_ORDER_YEAR_MODALITY: {
    _id: 0,
    finished_at: "$fix_book_date_start",
  },
  PAYMENT_DATE_ORDER_YEAR: {
    _id: 0,
    payment_date: "$sales_info.payment_date",
  },
  PATIENT_LOGIN: {
    _id: 1,
    patient_type: 1,
  },
  BRAIN_CHECK: {
    _id: 1,
    status: 1,
    exam_term: 1,
  },
  USK001: {
    _id: 1,
  },
  USK002: {
    _id: 1,
    status: 1,
    examStartDate: _createDateToStringStage("exam_start_date", "%Y/%m/%d"),
  },
  NOTIFICATIONS: {
    _id: 1,
    deliveriedAt: _createDateToStringStage("deliveried_at"),
    title: 1,
    detail: 1,
    imageUrl: "$image_url",
  },
  STRIPE_SUBSCRIPTION: {
    _id: 0,
    stripeSubscriptionId: "$patient_subscriptions_info.stripe_subscription_id",
  },
  INVITATION_USER: {
    _id: 1,
    name: {
      $concat: ["$user_info.name_sei", " ", "$user_info.name_mei"],
    },
  },
  USG012: {
    _id: 1,
    payment_date: _createDateToStringStage("payment_date", "%Y/%m/%d"),
    category: 1,
    amount: 1,
  },
  ONE_SALE: {
    _id: 1,
    payment_date: 1,
    category: 1,
    amount: 1,
    tax_rate: 1,
    invoice_num: "$hospital_info.invoice_num",
    hospital_name: "$hospital_info.hospital_name",
    postal_code: "$hospital_info.postal_code",
    prefecture: "$hospital_info.prefecture",
    address: "$hospital_info.address",
    receiptIssueDate: "$receipt_issue_date",
    total_amount_per_tax: "$orders_info.total_amount_per_tax",
    transactions_info: {
      $map: {
        input: "$transactions_info",
        as: "transaction",
        in: {
          _id: "$$transaction._id",
          quantity: "$$transaction.quantity",
          total_amount: "$$transaction.total_amount",
          sku_name: "$$transaction.sku_name",
          item_name: "$$transaction.item_name",
          consumption_tax_rate: "$$transaction.consumption_tax_rate",
        },
      },
    },
  },
  SHIPPINGS: {
    _id: 1,
    companyName: "$company_name",
    taxCalculationType: "$tax_calculation_type",
    deliveryTimes: "$delivery_times",
    deliveryFee: "$delivery_fee",
    deliveryCondition: "$delivery_condition",
  },
};

const GROUP: { [key: string]: GroupStage } = {};

export const getMongoDb = (
  currentUser: Realm.User<
    Realm.DefaultFunctionsFactory,
    SimpleObject,
    Realm.DefaultUserProfileData
  > | null,
  collection: Collection,
) => {
  const serviceName = process.env.REACT_APP_MONGO_DB_SERVICE_NAME as string;
  const dbName = process.env.REACT_APP_MONGO_DB_DATABASE_NAME as string;
  const mongo = currentUser?.mongoClient(serviceName);
  if (!mongo) {
    return redirectToInvalidFunctionPage();
  }

  return mongo.db(dbName).collection(collection);
};

// 設定の取得
export const getAggregateOneSetting = (
  conditions: MatchStage,
): PipelineStage[] => {
  const aggregate: PipelineStage[] = [
    { $match: conditions },
    { $limit: 1 },
    { $project: PROJECTS.ONE_SETTING },
  ];

  return aggregate;
};

// ユーザテーブルに紐づく患者テーブル1件検索
export const getAggregateOnePatientUser = (
  conditions: MatchStage,
): PipelineStage[] => {
  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.USERS.PATIENTS },
    { $unwind: "$patient_info" },
    { $lookup: LOOK_UP.USERS.PATIENT_SUBSCRIPTIONS },
    {
      $unwind: {
        path: "$patient_subscriptions_info",
        preserveNullAndEmptyArrays: true,
      },
    },
    { $lookup: LOOK_UP.USERS.PATIENT_SUBSCRIPTIONS_SUBSCRIPTION_PLANS },
    {
      $unwind: {
        path: "$subscription_plan_info",
        preserveNullAndEmptyArrays: true,
      },
    },
    { $lookup: LOOK_UP.USERS.PATIENTS_CART },
    { $unwind: { path: "$cart_info", preserveNullAndEmptyArrays: true } },
    { $lookup: LOOK_UP.USERS.PATIENTS_CAMPAIGN_COUPONS },
    {
      $unwind: {
        path: "$campaign_coupon_info",
        preserveNullAndEmptyArrays: true,
      },
    },
    { $lookup: LOOK_UP.USERS.PATIENT_INVITATIONS },
    {
      $unwind: {
        path: "$patient_invitation_info",
        preserveNullAndEmptyArrays: true,
      },
    },
    { $match: conditions },
    { $limit: 1 },
    { $project: PROJECTS.ONE_PATIENT },
  ];

  return aggregate;
};

export const getAggregateOneItem = (
  conditions: MatchStage,
): PipelineStage[] => {
  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.ITEMS.SKUS },
    { $lookup: LOOK_UP.ITEMS.ITEM_TAGS },
    { $lookup: LOOK_UP.ITEMS.OPTION_CONFIGS },
    { $lookup: LOOK_UP.ITEMS.SKUS_OPTION_CONTENTS },
    {
      $match: {
        ...conditions,
        status: { $ne: ItemsSkusStatusID.DELETED },
      },
    },
    {
      $addFields: {
        option_configs_info: {
          $filter: {
            input: "$option_configs_info",
            as: "option",
            cond: { $eq: ["$$option.status", 1] },
          },
        },
      },
    },
    {
      $project: {
        ...PROJECTS.ONE_ITEMS,
        skus_info: {
          $filter: {
            input: "$skus_info",
            as: "sku",
            cond: { $ne: ["$$sku.status", ItemsSkusStatusID.DELETED] },
          },
        },
      },
    },
  ];

  return aggregate;
};

export const getAggregateOneOrder = (
  conditions: MatchStage,
): PipelineStage[] => {
  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.ORDERS.SALES },
    { $unwind: { path: "$sales_info", preserveNullAndEmptyArrays: true } },
    { $lookup: LOOK_UP.ORDERS.TRANSACTIONS },
    {
      $match: conditions,
    },
    { $project: PROJECTS.ONE_ORDERS },
  ];

  return aggregate;
};

// ユーザテーブルに紐づく患者テーブル1件検索
export const getAggregatePatientOrders = (
  conditions: MatchStage,
): PipelineStage[] => {
  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.ORDERS.SALES },
    { $lookup: LOOK_UP.USERS.PATIENT_SUBSCRIPTIONS },
    { $unwind: "$patient_subscriptions_info" },
    { $lookup: LOOK_UP.USERS.PATIENT_SUBSCRIPTIONS_SUBSCRIPTION_PLANS },
    { $unwind: "$subscription_plan_info" },
    { $match: conditions },
    { $limit: 1 },
    { $project: PROJECTS.ONE_PATIENT },
  ];

  return aggregate;
};

export const getAggregateUsa004 = (id: string) => {
  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.USERS.PATIENTS },
    { $unwind: { path: "$patient_info", preserveNullAndEmptyArrays: true } },
    { $match: { _id: id } },
    { $limit: 1 },
    { $project: PROJECTS.USA004 },
  ];

  return aggregate;
};

export const getAggregateUsf001 = (id: string) => {
  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.PATIENTS.PATIENTS_RECOMMENDATION_ITEMS },
    { $lookup: LOOK_UP.PATIENTS.PATIENTS_ITEMS },
    { $lookup: LOOK_UP.PATIENTS.PATIENTS_CART },
    { $unwind: { path: "$cart_info", preserveNullAndEmptyArrays: true } },
    { $match: { _id: id } },
    { $limit: 1 },
    { $project: PROJECTS.USF001 },
  ];

  return aggregate;
};

export const getAggregateUsf002 = (
  patientId: string,
  skip: number,
  limit: number,
) => {
  // 並び替え条件
  const sortConditions = _createSortConditions(
    "update_at",
    "asc",
    "update_at",
    "asc",
  );
  const facet = _createSearchAndCountFacet(
    sortConditions,
    skip,
    limit,
    PROJECTS.USF002,
  );

  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.ORDERS.SALES },
    { $unwind: { path: "$sales_info", preserveNullAndEmptyArrays: true } },
    { $lookup: LOOK_UP.ORDERS.TRANSACTIONS },
    {
      $match: {
        patient_id: patientId,
        status: { $ne: OrderStatusID.CART_KEPT },
      },
    },
    { $facet: facet },
  ];

  return aggregate;
};

export const getAggregateUsf004 = (skip: number, limit: number) => {
  // 並び替え条件
  const sortConditions = _createSortConditions("sort", "desc", "sort", "desc");
  const facet = _createSearchAndCountFacet(
    sortConditions,
    skip,
    limit,
    PROJECTS.USF004,
  );

  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.ITEMS.SKUS },
    {
      $match: { status: ItemsStatusID.VISIBLE },
    },
    { $match: { "skus_info.0": { $exists: true } } },
    { $facet: facet },
  ];

  return aggregate;
};

/**
 *  寄席のステータスを日付から計算します。(mongoDB用)
 *  実装の都合で、utileファイルにおけるgetYoseStatusFieldsにも同様のロジックがあります。
 */
const getYoseStatusFields = () => {
  const toDay = new Date();
  toDay.setHours(0, 0, 0, 0);

  const data = {
    status: {
      $switch: {
        branches: [
          {
            case: { $eq: ["$is_suspend", true] },
            then: YOSE_STATUS.suspend,
          },
          {
            case: { $lt: ["$streaming_end_date", toDay] },
            then: YOSE_STATUS.publicClose,
          },
          {
            case: { $lt: [toDay, "$streaming_date"] },
            then: YOSE_STATUS.preDelivery,
          },
          {
            case: { $lt: ["$new_period", toDay] },
            then: YOSE_STATUS.publicOpen,
          },
        ],
        default: YOSE_STATUS.newPeriod,
      },
    },
  };

  return data;
};

/**
 *  寄席における新着数取得のaggregateを取得します。
 */
export const getAggregateYoseNewCount = () => {
  const aggregate: PipelineStage[] = [
    {
      $addFields: getYoseStatusFields(),
    },
    {
      $match: {
        status: YOSE_STATUS.newPeriod,
      },
    },
    {
      $count: "totalCount",
    },
  ];

  return aggregate;
};

export const getYoseVideo = (props: {
  statusDisplayed: ValueOf<typeof YOSE_STATUS>[];
  skip: number;
  perPage: number;
}) => {
  const { statusDisplayed, skip, perPage } = props;

  // 検索条件
  const searchConditions: MatchStage = {
    status: { $in: statusDisplayed },
  };

  // 並び替え条件
  const sortConditions = _createSortConditions(
    "created_at",
    "desc",
    "status",
    "asc",
  );

  // 1ページ分のデータと全体件数条件
  const facet = _createSearchAndCountFacet(
    sortConditions,
    skip,
    perPage,
    PROJECTS.USE002,
  );

  const aggregate: PipelineStage[] = [
    {
      $addFields: getYoseStatusFields(),
    },
    { $match: searchConditions },
    { $facet: facet },
  ];

  return aggregate;
};

export const getAggregateCampaigns = (props: {
  selectedEmbeddedPage: ValueOf<typeof EmbeddedPageID>;
}) => {
  const { selectedEmbeddedPage } = props;
  const aggregate: PipelineStage[] = [
    {
      $match: {
        embedded_page: selectedEmbeddedPage,
        status: CampaignsStatusID.NOW_OPEN,
        is_registered: true,
        $expr: { $lt: ["$delivery_date", new Date()] }, // 配信日時 < 現在の日付
        $or: [
          { $expr: { $lte: [new Date(), "$suspend_date"] } },
          { is_limited: false },
        ], // 現在の日付 <= 終了日時 or  期限制限なし
      },
    },
    { $sort: { embedded_location: -1 } },
    {
      $project: {
        title: 1,
        url: 1,
        embedded_location: 1,
        pc_image: 1,
        sp_image: 1,
      },
    },
  ];

  return aggregate;
};

export const getSubscriptionPlans = () => {
  const aggregate: PipelineStage[] = [
    { $match: { plan_type: USER_PLAN_CATEGORY.standardPlan } },
    { $sort: { span: 1 } },
  ];

  return aggregate;
};

// 会員の未読メッセージのカウント取得
export const getNewPatientMessageCount = (id: string) => {
  const aggregate: PipelineStage[] = [
    {
      $match: {
        patient_id: id,
        status: PatientMessageRelationshipStatusID.UNREAD,
      },
    },
    { $count: "count" },
  ];

  return aggregate;
};

export const getAggregatePatientInterview = (id: string) => {
  const aggregate: PipelineStage[] = [
    { $match: { patient_id: id } },
    { $sort: { created_at: -1 } },
    { $project: PROJECTS.PATIENT_INTERVIEWS },
  ];

  return aggregate;
};

export const getAggregatePatientOnlineTests = (id: string) => {
  const aggregate: PipelineStage[] = [
    { $match: { patient_id: id } },
    { $sort: { count_of_questionnaire: -1 } },
    { $project: PROJECTS.PATIENT_ONLINE_TESTS },
  ];

  return aggregate;
};

export const getAggregatePatientNouknow = (id: string) => {
  const aggregate: PipelineStage[] = [
    { $match: { patient_id: id } },
    { $sort: { finished_at: -1 } },
    { $project: PROJECTS.PATIENT_NOUKNOWS },
  ];

  return aggregate;
};

export const getAggregatePatientMri = (
  id: string,
  yearsSinceActivation: number | undefined,
) => {
  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.PATIENT_MODALITY_BOOKS.HOSPITALS },
    { $unwind: { path: "$hospital_info", preserveNullAndEmptyArrays: true } },
    {
      $match: {
        patient_id: id,
        modality: ModalityID.MRI,
        mri_status: {
          $nin: [
            MriStatusID.WITHDRAWN_AFTER_CONFIRMATION,
            MriStatusID.NG_BEFORE_CONFIRMATION,
          ],
        },
        ...(yearsSinceActivation && { examination_year: yearsSinceActivation }),
      },
    },
    { $sort: { created_at: -1 } },
    { $project: PROJECTS.PATIENT_MRI },
  ];

  return aggregate;
};

export const getAggregatePatientPet = (
  id: string,
  yearsSinceActivation: number | undefined,
) => {
  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.PATIENT_MODALITY_BOOKS.HOSPITALS },
    { $unwind: { path: "$hospital_info", preserveNullAndEmptyArrays: true } },
    {
      $match: {
        patient_id: id,
        modality: ModalityID.PET,
        ...(yearsSinceActivation && { examination_year: yearsSinceActivation }),
      },
    },
    { $sort: { created_at: -1 } },
    { $project: PROJECTS.PATIENT_PET },
  ];

  return aggregate;
};

export const getAggregateMriHospitals = (prefecture: string) => {
  const aggregate: PipelineStage[] = [
    {
      $match: {
        status: HospitalStatusID.IN_USE,
        has_mri: true,
        prefecture,
      },
    },
    { $project: PROJECTS.MRI_HOSPITALS },
  ];

  return aggregate;
};

export const getAggregatePetHospitals = (prefecture: string) => {
  const aggregate: PipelineStage[] = [
    {
      $match: {
        status: HospitalStatusID.IN_USE,
        has_pet: true,
        prefecture,
      },
    },
    { $project: PROJECTS.PET_HOSPITALS },
  ];

  return aggregate;
};

export const getAggregateUsc001 = (selectedYear: string, id: string) => {
  // 検索条件
  const searchConditions: MatchStage = {
    patient_id: id,
    status: { $ne: PatientNouKnowStatusID.PRETEST },
  };
  // 年が指定されている場合は、年の範囲条件を追加
  if (selectedYear !== "All") {
    searchConditions.finished_at = {
      $gte: new Date(
        Date.UTC(parseInt(selectedYear), 0, 1, -JST_OFFSET_HOURS, 0, 0),
      ),
      $lte: new Date(Date.UTC(parseInt(selectedYear), 11, 31, 14, 59, 59)),
    };
  }
  const aggregate: PipelineStage[] = [
    { $match: searchConditions },
    { $sort: { finished_at: -1 } },
    { $project: PROJECTS.USC001 },
  ];

  return aggregate;
};

export const getAggregateUsc002 = (id: string) => {
  const aggregate: PipelineStage[] = [
    {
      $match: {
        patient_id: id,
        modality: ModalityID.MRI,
        mri_status: {
          $in: [
            MriStatusID.RESERVATION_CONFIRMED,
            MriStatusID.DONE_IMAGE_NOT_LINKED,
            MriStatusID.DONE_AI_DIAGNOSING,
            MriStatusID.DONE_AI_DIAGNOSED,
            MriStatusID.DONE_DOCTOR_DIAGNOSED,
            MriStatusID.USER_REPORTED,
            MriStatusID.NO_EXAMINATION,
          ],
        },
      },
    },
    { $sort: { fix_book_date_start: -1 } },
    { $project: PROJECTS.USC002 },
  ];

  return aggregate;
};

export const getAggregateUsc003 = (id: string) => {
  const aggregate: PipelineStage[] = [
    {
      $match: {
        patient_id: id,
        modality: ModalityID.PET,
        pet_status: {
          $in: [
            PetStatusID.NO_EXAMINATION,
            PetStatusID.RESERVATION_CONFIRMED,
            PetStatusID.EXAMINED,
            PetStatusID.DIAGNOSED,
            PetStatusID.USER_REPORTED,
          ],
        },
      },
    },
    { $sort: { fix_book_date_start: -1 } },
    { $project: PROJECTS.USC003 },
  ];

  return aggregate;
};

export const getAggregateUsc004 = (selectedDate: string, id: string) => {
  // 検索条件
  const searchConditions: MatchStage = {
    patient_id: id,
    status: { $ne: PatientNouKnowStatusID.PRETEST },
  };

  // 年が指定されている場合は、年の範囲条件を追加
  if (selectedDate !== "All") {
    const date = new Date(selectedDate);
    searchConditions.finished_at = {
      $gte: new Date(date.setHours(0, 0, 0, 0)),
      $lt: new Date(date.setHours(23, 59, 59, 999)),
    };
  }

  const aggregate: PipelineStage[] = [
    { $match: searchConditions },
    { $sort: { finished_at: -1 } },
    { $project: PROJECTS.USC001 },
  ];

  return aggregate;
};

export const getAggregateUsj001 = (id: string) => {
  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.PATIENT_MESSAGE_RELATIONSHIPS.MESSAGES },
    { $unwind: { path: "$message_info", preserveNullAndEmptyArrays: true } },
    { $match: { patient_id: id } },
    { $sort: { created_at: -1 } },
    { $project: PROJECTS.PATIENT_MESSAGE },
  ];

  return aggregate;
};

export const getAggregatePatientMessageById = (messageId: string) => {
  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.PATIENT_MESSAGE_RELATIONSHIPS.MESSAGES },
    { $unwind: { path: "$message_info", preserveNullAndEmptyArrays: true } },
    { $match: { _id: messageId } },
    { $limit: 1 },
    { $project: PROJECTS.PATIENT_MESSAGE },
  ];

  return aggregate;
};

export const getAggregateNotificationTop = () => {
  const aggregate: PipelineStage[] = [
    { $match: { status: NotificationStatusID.NOW_OPEN } },
    { $sort: { deliveried_at: -1 } },
    { $limit: 3 },
    { $project: PROJECTS.NOTIFICATION_TOP },
  ];

  return aggregate;
};

export const getAggregateInvitations = (
  limitCount: number | "all",
  id: string,
) => {
  let aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.PATIENT_INVITATIONS.USERS },
    { $unwind: { path: "$invitation_info", preserveNullAndEmptyArrays: true } },
    { $lookup: LOOK_UP.PATIENT_INVITATIONS.PATIENT_SUBSCRIPTIONS },
    {
      $unwind: { path: "$subscription_info", preserveNullAndEmptyArrays: true },
    },
    {
      $lookup:
        LOOK_UP.PATIENT_INVITATIONS.PATIENT_SUBSCRIPTIONS_SUBSCRIPTION_PLANS,
    },
    {
      $unwind: {
        path: "$subscription_plan_info",
        preserveNullAndEmptyArrays: true,
      },
    },
    { $match: { parent_patient_id: id } },
    { $sort: { created_at: 1 } },
    { $project: PROJECTS.PATIENT_INVITATIONS },
  ];
  if (limitCount !== "all") {
    aggregate = [...aggregate, { $limit: limitCount }];
  }

  return aggregate;
};

export const getAggregateUSD003 = (patientId: string | undefined) => {
  const aggregate: PipelineStage[] = [
    { $match: { patient_id: patientId } },
    { $sort: { register_date: 1 } },
  ];

  return aggregate;
};

export const getAggregateInterview = (props: {
  patientId: string | undefined;
}) => {
  const { patientId } = props;
  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.PATIENTS.PATIENTS_INVITATION_TICKETS },
    {
      $unwind: {
        path: "$invitation_tickets_info",
        preserveNullAndEmptyArrays: true,
      },
    },
    {
      $match: {
        patient_id: patientId,
        status: {
          $nin: [INTERVIEW_STATUS.canceled, INTERVIEW_STATUS.interviewDone],
        },
      },
    },
    { $project: PROJECTS.PATIENTS_INVITATION_TICKETS },
  ];

  return aggregate;
};

export const getAggregateUsa028 = (id: string) => {
  const aggregate: PipelineStage[] = [
    { $match: { parent_patient_id: id } },
    { $project: PROJECTS.USA028 },
  ];

  return aggregate;
};

export const getAggregateUsa029 = (id: string) => {
  const aggregate: PipelineStage[] = [
    { $match: { _id: id } },
    { $project: PROJECTS.USA029 },
  ];

  return aggregate;
};

export const getAggregateUsaInvitationsUser = (
  id: string,
  statusArray?: PATIENT_INVITATIONS_STATUS[],
) => {
  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.PATIENT_INVITATIONS.USERS },
    { $unwind: { path: "$invitation_info", preserveNullAndEmptyArrays: true } },
    { $lookup: LOOK_UP.PATIENT_INVITATIONS.PATIENT_SUBSCRIPTIONS },
    {
      $unwind: { path: "$subscription_info", preserveNullAndEmptyArrays: true },
    },
    {
      $lookup:
        LOOK_UP.PATIENT_INVITATIONS.PATIENT_SUBSCRIPTIONS_SUBSCRIPTION_PLANS,
    },
    {
      $unwind: {
        path: "$subscription_plan_info",
        preserveNullAndEmptyArrays: true,
      },
    },
  ];
  if (statusArray && statusArray.length > 0) {
    aggregate.push({
      $match: { invited_patient_id: id, status: { $in: statusArray } },
    });
  } else {
    aggregate.push({ $match: { invited_patient_id: id } });
  }

  aggregate.push({ $project: PROJECTS.PATIENT_INVITATIONS });

  return aggregate;
};

export const getAggregateUsa033 = (id: string, orderYear: string | null) => {
  // 注文日(年、月)の検索条件
  const orderDateConditions = _createOrderDateConditions(
    orderYear,
    null,
    "sales_info.payment_date",
  );
  const idConditions = { payment_patient_id: id };
  // 検索条件
  const searchConditions: MatchStage = {
    ...idConditions,
    ...orderDateConditions,
  };

  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.PATIENT_SUBSCRIPTIONS.USERS },
    { $unwind: { path: "$user_info" } },
    { $lookup: LOOK_UP.PATIENT_SUBSCRIPTIONS.SALES },
    { $unwind: { path: "$sales_info" } },
    { $match: searchConditions },
    { $project: PROJECTS.USA033 },
  ];

  return aggregate;
};

export const getAggregateAllOrderYear = (id: string) => {
  const aggregate: PipelineStage[] = [
    { $match: { patient_id: id, status: PatientNouKnowStatusID.COMPLETED } },
    { $project: PROJECTS.ALL_ORDER_YEAR },
  ];

  return aggregate;
};

export const getAggregateUsa031 = (id: string, year: string | null) => {
  const orderYear = year === "すべて" ? null : year;
  // 注文日(年、月)の検索条件
  const orderDateConditions = _createOrderDateConditions(
    orderYear,
    null,
    "finished_at",
  );
  const patientIdConditions = { patient_id: id };
  const statusConditions = {
    status: {
      $nin: [
        PatientNouKnowStatusID.PRETEST,
        PatientNouKnowStatusID.CANCELLATION,
      ],
    },
  };

  // 検索条件
  const searchConditions: MatchStage = {
    ...patientIdConditions,
    ...statusConditions,
    ...orderDateConditions,
  };
  const aggregate: PipelineStage[] = [
    { $match: searchConditions },
    { $project: PROJECTS.USC001 },
  ];

  return aggregate;
};

export const getAggregateSubscriptionOrderYear = (id: string) => {
  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.PATIENT_SUBSCRIPTIONS.SALES },
    { $unwind: { path: "$sales_info" } },
    { $match: { payment_patient_id: id } },
    { $project: PROJECTS.PAYMENT_DATE_ORDER_YEAR },
  ];

  return aggregate;
};

export const getAggregatePatientLogin = (id: string) => {
  const aggregate: PipelineStage[] = [
    { $match: { _id: id } },
    { $project: PROJECTS.PATIENT_LOGIN },
  ];

  return aggregate;
};

export const getAggregateUsaInvitations = (id: string) => {
  const aggregate: PipelineStage[] = [
    { $match: { invited_patient_id: id, status: UserStatusID.UNREGISTERED } },
    { $project: PROJECTS.USA028 },
  ];

  return aggregate;
};

export const getAggregateBrainCheck = (id: string) => {
  const searchConditions: MatchStage = {
    patient_id: id,
  };

  const aggregate: PipelineStage[] = [
    { $match: searchConditions },
    { $project: PROJECTS.BRAIN_CHECK },
  ];

  return aggregate;
};

export const getAggregateUsk001 = (id: string) => {
  const searchConditions: MatchStage = {
    patient_id: id,
    status: PatientBrainCheckStatusID.NOT_TESTED,
  };

  const aggregate: PipelineStage[] = [
    { $match: searchConditions },
    { $project: PROJECTS.USK001 },
  ];

  return aggregate;
};

export const getAggregateUsk002 = (selectedResult: string, id: string) => {
  // 検索条件
  let statusConditions;
  if (selectedResult === "1") {
    statusConditions = {
      status: PatientBrainCheckStatusID.COMPLETED,
    };
  } else if (selectedResult === "2") {
    statusConditions = {
      status: PatientBrainCheckStatusID.REPORTED,
    };
  } else {
    statusConditions = {
      status: { $ne: PatientBrainCheckStatusID.NOT_TESTED },
    };
  }

  const searchConditions: MatchStage = {
    patient_id: id,
    ...statusConditions,
  };

  const aggregate: PipelineStage[] = [
    { $match: searchConditions },
    { $sort: { exam_start_date: -1 } },
    { $project: PROJECTS.USK002 },
  ];

  return aggregate;
};

export const getCampaignCoupons = (code: string) => {
  const aggregate: PipelineStage[] = [
    {
      $match: { campaign_code: code, status: CampaignCouponsStatusID.APPLY },
    },
    { $project: PROJECTS.USA024 },
  ];

  return aggregate;
};

export const getCampaignTerm = (id: string | number) => {
  const aggregate: PipelineStage[] = [
    { $match: { campaign_coupon_id: id } },
    { $project: PROJECTS.USA024Term },
  ];

  return aggregate;
};

export const getAggregateUsm001 = () => {
  const aggregate: PipelineStage[] = [
    { $match: { status: NotificationStatusID.NOW_OPEN } },
    { $sort: { deliveried_at: -1 } },
    { $project: PROJECTS.NOTIFICATIONS },
  ];

  return aggregate;
};

export const getAggregateNotificationById = (notificationId: string) => {
  const aggregate: PipelineStage[] = [
    { $match: { _id: notificationId } },
    { $limit: 1 },
    { $project: PROJECTS.NOTIFICATIONS },
  ];

  return aggregate;
};

// ユーザテーブルに紐づくサブスクリプション検索
export const getAggregateStripeSubscription = (
  conditions: MatchStage,
): PipelineStage[] => {
  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.USERS.PATIENT_SUBSCRIPTIONS },
    {
      $unwind: {
        path: "$patient_subscriptions_info",
        preserveNullAndEmptyArrays: true,
      },
    },
    { $match: conditions },
    { $limit: 1 },
    { $project: PROJECTS.STRIPE_SUBSCRIPTION },
  ];

  return aggregate;
};

// patient_invitationsに紐づくusersを1件検索
export const getAggregateInvitationUser = (
  conditions: MatchStage,
): PipelineStage[] => {
  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.PATIENT_INVITATIONS.INVITATIONS_USER },
    { $unwind: "$user_info" },
    { $match: conditions },
    { $limit: 1 },
    { $project: PROJECTS.INVITATION_USER },
  ];

  return aggregate;
};

export const getAggregateReceipts = (props: {
  id: string;
  skip: number;
  perPage: number;
}) => {
  const { id, skip, perPage } = props;

  // 検索条件
  const searchConditions: MatchStage = {
    status: SALES_STATUS.salesCompleted,
    patient_id: id,
  };

  // 並び替え条件
  const sortConditions: SortStage = {
    payment_date: -1,
  };

  // 1ページ分のデータと全体件数条件
  const facet = _createSearchAndCountFacet(
    sortConditions,
    skip,
    perPage,
    PROJECTS.USG012,
  );

  const aggregate: PipelineStage[] = [
    { $match: searchConditions },
    { $facet: facet },
  ];

  return aggregate;
};

export const getAggregateOneSale = (
  conditions: MatchStage,
): PipelineStage[] => {
  const aggregate: PipelineStage[] = [
    { $lookup: LOOK_UP.SALES.PATIENT_MODALITY_BOOKS },
    {
      $unwind: {
        path: "$patient_modality_book_info",
        preserveNullAndEmptyArrays: true,
      },
    },
    { $lookup: LOOK_UP.SALES.PATIENT_MODALITY_BOOKS_HOSPITALS },
    {
      $unwind: {
        path: "$hospital_info",
        preserveNullAndEmptyArrays: true,
      },
    },
    { $lookup: LOOK_UP.SALES.ORDERS },
    {
      $unwind: {
        path: "$orders_info",
        preserveNullAndEmptyArrays: true,
      },
    },
    { $lookup: LOOK_UP.SALES.TRANSACTIONS },
    { $match: conditions },
    { $limit: 1 },
    { $project: PROJECTS.ONE_SALE },
  ];

  return aggregate;
};

export const getAggregateShippings = (): PipelineStage[] => {
  const aggregate: PipelineStage[] = [
    { $limit: 1 },
    { $project: PROJECTS.SHIPPINGS },
  ];

  return aggregate;
};

export const getAggregateUsc021AllOrderYear = (id: string) => {
  const aggregate: PipelineStage[] = [
    {
      $match: {
        patient_id: id,
        status: { $ne: PatientNouKnowStatusID.PRETEST },
      },
    },
    { $project: PROJECTS.ALL_ORDER_YEAR },
  ];

  return aggregate;
};

export const getAggregateUsc021AllOrderYearModality = (id: string) => {
  const aggregate: PipelineStage[] = [
    {
      $match: {
        patient_id: id,
        $or: [
          {
            pet_status: {
              $in: [
                PetStatusID.NO_EXAMINATION,
                PetStatusID.RESERVATION_CONFIRMED,
                PetStatusID.EXAMINED,
                PetStatusID.DIAGNOSED,
                PetStatusID.USER_REPORTED,
              ],
            },
          },
          {
            mri_status: {
              $in: [
                MriStatusID.RESERVATION_CONFIRMED,
                MriStatusID.DONE_IMAGE_NOT_LINKED,
                MriStatusID.DONE_AI_DIAGNOSING,
                MriStatusID.DONE_AI_DIAGNOSED,
                MriStatusID.DONE_DOCTOR_DIAGNOSED,
                MriStatusID.USER_REPORTED,
                MriStatusID.NO_EXAMINATION,
              ],
            },
          },
        ],
      },
    },
    { $project: PROJECTS.ALL_ORDER_YEAR_MODALITY },
  ];

  return aggregate;
};

export const getAggregateUsc021AllOrderYearBrain = (id: string) => {
  const aggregate: PipelineStage[] = [
    {
      $match: {
        patient_id: id,
        status: { $ne: PatientBrainCheckStatusID.NOT_TESTED },
      },
    },
    { $project: PROJECTS.ALL_ORDER_YEAR_BRAIN },
  ];

  return aggregate;
};

export const getAggregateUsa022 = (id: string, year: string | null) => {
  const orderYear = year === "すべて" ? null : year;
  // 注文日(年、月)の検索条件
  const orderDateConditions = _createOrderDateConditions(
    orderYear,
    null,
    "exam_start_date",
  );
  const patientIdConditions = { patient_id: id };
  const statusConditions = {
    status: { $ne: PatientBrainCheckStatusID.NOT_TESTED },
  };

  // 検索条件
  const searchConditions: MatchStage = {
    ...patientIdConditions,
    ...statusConditions,
    ...orderDateConditions,
  };
  const aggregate: PipelineStage[] = [
    { $match: searchConditions },
    { $project: PROJECTS.USC021 },
  ];

  return aggregate;
};

export const getAggregateUsc024 = (id: string, year: string | null) => {
  const orderYear = year === "すべて" ? null : year;
  const orderDateConditions = _createOrderDateConditions(
    orderYear,
    null,
    "fix_book_date_start",
  );

  const patientIdConditions = { patient_id: id };
  const modalityConditions = { modality: ModalityID.MRI };
  const statusConditions = {
    mri_status: {
      $in: [
        MriStatusID.RESERVATION_CONFIRMED,
        MriStatusID.DONE_IMAGE_NOT_LINKED,
        MriStatusID.DONE_AI_DIAGNOSING,
        MriStatusID.DONE_AI_DIAGNOSED,
        MriStatusID.DONE_DOCTOR_DIAGNOSED,
        MriStatusID.USER_REPORTED,
        MriStatusID.NO_EXAMINATION,
      ],
    },
  };

  const searchConditions: MatchStage = {
    ...patientIdConditions,
    ...modalityConditions,
    ...statusConditions,
    ...orderDateConditions,
  };
  const aggregate: PipelineStage[] = [
    { $match: searchConditions },
    { $sort: { fix_book_date_start: -1 } },
    { $project: PROJECTS.USC002 },
  ];

  return aggregate;
};

export const getAggregateUsc025 = (id: string, year: string | null) => {
  const orderYear = year === "すべて" ? null : year;
  const orderDateConditions = _createOrderDateConditions(
    orderYear,
    null,
    "fix_book_date_start",
  );

  const patientIdConditions = { patient_id: id };
  const modalityConditions = { modality: ModalityID.PET };
  const statusConditions = {
    pet_status: {
      $in: [
        PetStatusID.NO_EXAMINATION,
        PetStatusID.RESERVATION_CONFIRMED,
        PetStatusID.EXAMINED,
        PetStatusID.DIAGNOSED,
        PetStatusID.USER_REPORTED,
      ],
    },
  };

  const searchConditions: MatchStage = {
    ...patientIdConditions,
    ...modalityConditions,
    ...statusConditions,
    ...orderDateConditions,
  };
  const aggregate: PipelineStage[] = [
    { $match: searchConditions },
    { $sort: { fix_book_date_start: -1 } },
    { $project: PROJECTS.USC003 },
  ];

  return aggregate;
};
