import { useCallback, useEffect, useState } from "react";
import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import HeaderUser from "../components/Header/HeaderUser";
import Sheet from "../components/Sheet/Sheet";
import Title from "../components/Title/Title";
import Input from "../components/Input/Input";
import SpButton from "../components/Button/SpButton";
import SpFormSet from "../components/FormSet/SpFormSet";
import RadioButtonGroup from "../components/RadioButton/RadioButtonGroup";
import SpDropdown from "../components/Dropdown/SpDropdown";
import { useNavigateWithUrl } from "../hooks/base/usePageTransitionCustom";
import useUserRegisterInput, {
  setNameSei,
  setNameMei,
  setNameSeiKana,
  setNameMeiKana,
  setGender,
  setBirthYear,
  setBirthMonth,
  setBirthDay,
  setTel,
  // setSelectedNotice,
  checkUserInvitationInputError,
} from "../hooks/input/useUserInvitationInput";
import {
  DROPDOWN_MONTH_OPTIONS,
  DROPDOWN_YEAR_OPTIONS,
  RADIO_GENDER_ITEMS,
  RADIO_NOTICE_ITEMS,
} from "../constants/common";
import { PATIENT } from "../constants/pagePaths";
import PatientFooter from "../features/PatientFooter";
import { generateDays } from "../utils/utils";
import TermsOfServiceCheckBox, {
  termsOfServiceCheckBoxItems,
} from "../components/Checkbox/TermsOfServiceCheckBox";
import usePolicyChecks from "../hooks/common/usePolicyChecks";

const USA025 = () => {
  const [dayOptions, setDayOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const navigate = useNavigateWithUrl();
  const [checkedItems, setCheckedItems] = useState(termsOfServiceCheckBoxItems);
  const [policyChecks] = usePolicyChecks();
  const [nameSeiKanaState, setNameSeiKanaState] = useState("");
  const [nameMeiKanaState, setNameMeiKanaState] = useState("");

  const [
    {
      nameSei,
      nameMei,
      nameSeiKana,
      nameMeiKana,
      gender,
      birthYear,
      birthMonth,
      birthDay,
      tel,
      // selectedNotice,
    },
  ] = useUserRegisterInput();

  useEffect(() => {
    if (policyChecks.length > 0) {
      setCheckedItems((prevCheckedItems) => {
        const updatedCheckboxItems = prevCheckedItems.map((item, index) => {
          const check = policyChecks[index];

          return index < policyChecks.length
            ? { ...item, checked: check }
            : item;
        });

        return updatedCheckboxItems;
      });
    }
  }, [policyChecks, setCheckedItems]);

  useEffect(() => {
    if (birthYear && birthMonth) {
      const validDays = generateDays(birthYear.value, birthMonth.value);
      setDayOptions(validDays.map((day) => ({ label: `${day}`, value: day })));

      // 既に選択されている日が有効な日より多い場合はリセット
      if (birthDay && Number(birthDay.value) > validDays.length) {
        setBirthDay("");
      }
    } else {
      // 年と月が選択されていない場合は日をリセット
      setDayOptions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [birthYear, birthMonth]);

  // 入力内容確認ボタン押下
  const navigateToConfirm = useCallback(() => {
    navigate(PATIENT.USA026);
  }, [navigate]);

  const handleGoBack = () => navigate(PATIENT.USA002);

  return (
    <div className="admin-area user-body-sp-full-height">
      <LayoutBox direction="column" fullWidth>
        <HeaderUser withRightControl={false} />
        <div className="admin-inner area-with-header-footer">
          <Title
            padding="16px 108px 24px 108px"
            background="default"
            header="お客様情報の入力"
            withButton={false}
            borderBottom="primary"
          />
          <Sheet padding="40px 0" className="util-sp-py-24">
            <LayoutBox direction="column" maxWidth="1063px" fullWidth gap="2x">
              <Sheet type="card" padding="16px 16px 32px 16px">
                <LayoutBox direction="column" fullWidth gap="3x">
                  <Title
                    type="h2"
                    header="会員情報"
                    withButton={false}
                    borderBottom="primaryLight"
                  />
                  <Text size="xl">
                    ご自身（お支払いをされる方）の情報を入力してください。
                  </Text>
                  <SpFormSet
                    label="お名前"
                    base
                    required="badge"
                    labelSize="medium"
                    labelWidth="200px"
                    spToColumnAndFullWidth
                  >
                    <LayoutBox
                      gap="1x"
                      direction="column"
                      className="util-py-8"
                      minWidth="400px"
                    >
                      <LayoutBox gap="1x" fullWidth>
                        <SpFormSet
                          label="姓"
                          vertical
                          errorTextList={[nameSei.validationMessage]}
                        >
                          <Input
                            width="100%"
                            value={nameSei.value}
                            onChange={(value) => setNameSei(value)}
                            error={nameSei.isError}
                          />
                        </SpFormSet>
                        <SpFormSet
                          label="名"
                          vertical
                          errorTextList={[nameMei.validationMessage]}
                        >
                          <Input
                            width="100%"
                            value={nameMei.value}
                            onChange={(value) => setNameMei(value)}
                            error={nameMei.isError}
                          />
                        </SpFormSet>
                      </LayoutBox>
                      <LayoutBox gap="1x" fullWidth>
                        <SpFormSet
                          label="セイ"
                          vertical
                          errorTextList={[nameSeiKana.validationMessage]}
                        >
                          <Input
                            width="100%"
                            value={nameSeiKanaState}
                            onChange={(value) => setNameSeiKanaState(value)}
                            onBlur={() => setNameSeiKana(nameSeiKanaState)}
                            error={nameSeiKana.isError}
                          />
                        </SpFormSet>
                        <SpFormSet
                          label="メイ"
                          vertical
                          errorTextList={[nameMeiKana.validationMessage]}
                        >
                          <Input
                            width="100%"
                            value={nameMeiKanaState}
                            onChange={(value) => setNameMeiKanaState(value)}
                            onBlur={() => setNameMeiKana(nameMeiKanaState)}
                            error={nameMeiKana.isError}
                          />
                        </SpFormSet>
                      </LayoutBox>
                    </LayoutBox>
                  </SpFormSet>
                  <SpFormSet
                    label="性別"
                    base
                    required="badge"
                    labelSize="medium"
                    labelWidth="200px"
                    spToColumnAndFullWidth
                  >
                    <LayoutBox minWidth="400px" className="util-sp-full-width">
                      <RadioButtonGroup
                        name="RadioGender"
                        items={RADIO_GENDER_ITEMS}
                        onChange={(value) => setGender(value)}
                        selectedValue={gender.value}
                        withBorder
                        width="100%"
                        column={2}
                        radioGap="8px"
                      />
                    </LayoutBox>
                  </SpFormSet>
                  <SpFormSet
                    label="生年月日"
                    base
                    required="badge"
                    labelSize="medium"
                    labelWidth="200px"
                    spToColumnAndFullWidth
                    errorTextList={[
                      birthYear.validationMessage,
                      birthMonth.validationMessage,
                      birthDay.validationMessage,
                    ]}
                  >
                    <LayoutBox gap="1x" direction="column" fullWidth>
                      <LayoutBox gap="1x" fullWidth align="center">
                        <SpDropdown
                          placeholder="XXXX（昭和37）"
                          items={DROPDOWN_YEAR_OPTIONS}
                          onChange={(value) => setBirthYear(value)}
                          width="256px"
                          value={birthYear.value}
                        />
                        <Text size="xl" color="neutralUserBlack">
                          年
                        </Text>
                      </LayoutBox>
                      <LayoutBox gap="1x" fullWidth>
                        <LayoutBox gap="1x" align="center">
                          <SpDropdown
                            placeholder="MM"
                            items={DROPDOWN_MONTH_OPTIONS}
                            onChange={(value) => setBirthMonth(value)}
                            width="97px"
                            value={birthMonth.value}
                          />
                          <Text size="xl" color="neutralUserBlack" width="45px">
                            月
                          </Text>
                        </LayoutBox>
                        <LayoutBox gap="1x" align="center">
                          <SpDropdown
                            placeholder="DD"
                            items={dayOptions}
                            onChange={(value) => setBirthDay(value)}
                            width="97px"
                            value={birthDay.value}
                          />
                          <Text size="xl" color="neutralUserBlack" width="45px">
                            日
                          </Text>
                        </LayoutBox>
                      </LayoutBox>
                    </LayoutBox>
                  </SpFormSet>
                  <SpFormSet
                    label="電話番号"
                    base
                    required="badge"
                    labelSize="medium"
                    labelWidth="200px"
                    spToColumnAndFullWidth
                    errorTextList={[tel.validationMessage]}
                  >
                    <Input
                      value={tel.value}
                      inputMode="tel"
                      width="335px"
                      onChange={(value) => setTel(value)}
                      error={tel.isError}
                    />
                  </SpFormSet>
                </LayoutBox>
              </Sheet>

              {/* FIXME: 仕様未決定のため、一旦非表示 */}
              {/* <Sheet type="card" padding="16px 16px 32px 16px">
                <LayoutBox direction="column" fullWidth gap="3x">
                  <Title
                    type="h2"
                    header="メール通知"
                    withButton={false}
                    borderBottom="primaryLight"
                  />
                  <LayoutBox fullWidth>
                    <SpFormSet
                      errorTextList={
                        selectedNotice.validationMessage
                          ? ["メール通知が選択されていません。"]
                          : []
                      }
                    >
                      <RadioButtonGroup
                        name="RadioNotice"
                        items={RADIO_NOTICE_ITEMS}
                        onChange={(value) => setSelectedNotice(value)}
                        selectedValue={selectedNotice.value}
                        withBorder
                        width="100%"
                        column={2}
                        radioGap="8px"
                      />
                    </SpFormSet>
                  </LayoutBox>
                </LayoutBox>
              </Sheet> */}
              <LayoutBox fullWidth>
                <TermsOfServiceCheckBox
                  checkedItems={checkedItems}
                  setCheckedItems={setCheckedItems}
                />
              </LayoutBox>
              <LayoutBox
                justify="between"
                gap="2x"
                className="util-sp-full-width util-mx-auto"
                wrapReverse
                width="1064px"
              >
                <SpButton
                  type="secondary"
                  width="400px"
                  size="medium"
                  withArrowLeft
                  mobileFullWidth
                  onClick={handleGoBack}
                >
                  戻る
                </SpButton>
                <SpButton
                  withArrowRight
                  disabled={
                    checkUserInvitationInputError() ||
                    checkedItems.some((checkedItem) => !checkedItem.checked)
                  }
                  type="primary"
                  width="400px"
                  size="medium"
                  onClick={navigateToConfirm}
                  mobileFullWidth
                >
                  入力内容の確認
                </SpButton>
              </LayoutBox>
            </LayoutBox>
          </Sheet>
          <div />
        </div>
      </LayoutBox>
      <PatientFooter />
    </div>
  );
};

export default USA025;
