import { useState } from "react";

import { useRealmApp } from "../../../contexts/RealmApp";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import useCheckUsersEmail from "../../common/useCheckUsersEmail";
import { setExistingEmailError } from "../../input/useEmailInput";

const useUsg017DbActions = () => {
  const realmAppContext = useRealmApp();
  const [error, setError] = useState<Error | null>(null);
  const { checkExistingEmail, error: existingEmailError } =
    useCheckUsersEmail();

  const handleUpdate = async (_id: string, email: string): Promise<boolean> => {
    try {
      // メール重複チェック
      if (await checkExistingEmail(email)) {
        setExistingEmailError();

        return false; // 処理失敗を明示
      }

      // ファンクション
      await realmAppContext.app.currentUser?.functions.changeEmail({
        userId: _id,
        newEmail: email,
        isAdmin: false,
      });

      return true; // 処理成功を明示
    } catch (err) {
      setError(checkActionErr(err));

      return false; // エラー発生時も失敗を明示
    }
  };

  return {
    actionError: error,
    existingEmailError,
    handleUpdate,
  };
};

export default useUsg017DbActions;
