import { useMemo } from "react";
import Enjoy from "../../assets/enjoy.png";
import OnlineVaudeville from "../../assets/online_vaudeville_title.png";
import OnlineVaudevilleVertical from "../../assets/online_vaudeville_title_vertical.png";
import { YOSE_STATUS } from "../../constants/common";
import { PATIENT } from "../../constants/pagePaths";
import { useNavigateCustom } from "../../hooks/base/usePageTransitionCustom";
import { setId } from "../../hooks/common/useId";
import useGetYoseVideoActions from "../../hooks/common/useGetYoseVideoActions";
import Button from "../Button/Button";
import SpButton from "../Button/SpButton";
import FormSet from "../FormSet/FormSet";
import USE002ImageContainer from "../Image/USE002/ImageContainer";
import LayoutBox from "../LayoutBox/LayoutBox";
import Sheet from "../Sheet/Sheet";
import Text from "../Text/Text";
import { useCheckErrorThrowError } from "../../utils/checkError";

const SectionOnlineVaudeville = () => {
  const navigate = useNavigateCustom();

  const statusDisplayed = useMemo(
    () => [YOSE_STATUS.newPeriod, YOSE_STATUS.publicOpen],
    [],
  );
  const { videos, error } = useGetYoseVideoActions({
    statusDisplayed,
    perPage: 3,
  });

  const goToYoseDetails = (id: string) => {
    setId(id);
    navigate(PATIENT.USE004);
  };

  const goToYoseList = () => {
    navigate(PATIENT.USE002);
  };

  const getTabMenu = (status: ValueOf<typeof YOSE_STATUS>) => {
    if (status === YOSE_STATUS.newPeriod) return "new";

    if (status === YOSE_STATUS.publicClose) return "end";

    // 公開中
    return undefined;
  };

  // エラー処理
  useCheckErrorThrowError([error]);

  if (videos.length === 0) return <></>;

  return (
    <>
      <div className="online-vaudeville util-full-width">
        <Sheet minWidth="1076px" padding="32px" relative>
          <div className="online-vaudeville util-full-width">
            <Sheet type="colored-border" width="100%">
              <LayoutBox
                direction="column"
                gap="3x"
                justify="center"
                align="center"
                fullWidth
                maxWidth="1115px"
              >
                <div className="online-vaudeville__content">
                  <div className="online-vaudeville__image--sp">
                    <img src={Enjoy} alt="enjoy" className="image--enjoy" />
                    <img src={OnlineVaudeville} alt="online_vaudeville" />
                  </div>
                  <div className="online-vaudeville__image--pc">
                    <img src={Enjoy} alt="enjoy" className="image--enjoy" />
                    <img
                      src={OnlineVaudevilleVertical}
                      alt="online_vaudeville"
                    />
                  </div>
                  <div style={{ flex: 1, overflowX: "auto" }}>
                    <LayoutBox gap="2x" className="online-vaudeville__list">
                      {videos.map((video, index) => (
                        <LayoutBox
                          direction="column"
                          justify="center"
                          align="center"
                          gap="2x"
                          key={index}
                          className="online-vaudeville__list__item"
                        >
                          <LayoutBox
                            direction="column"
                            justify="center"
                            align="center"
                            gap="3x"
                          >
                            <LayoutBox
                              direction="column"
                              justify="center"
                              align="center"
                              gap="2x"
                            >
                              <Sheet
                                type="border"
                                color="white"
                                withBorderRadius
                                withCornerText={getTabMenu(video.status)}
                                width="311px"
                                padding="16px"
                                relative
                              >
                                <LayoutBox
                                  gap="1x"
                                  justify="center"
                                  direction="column"
                                  fullWidth
                                >
                                  <USE002ImageContainer
                                    vimeoId={video.vimeo_id}
                                    width="279px"
                                    height="183px"
                                  />
                                  <FormSet
                                    base
                                    label="落語家"
                                    labelSize="small"
                                    labelWidth="74px"
                                  >
                                    <Text size="xl">{video.cast}</Text>
                                  </FormSet>
                                  <Text
                                    bold
                                    size="1xl"
                                    ellipsis
                                    ellipsisLine="2"
                                    titleFont
                                  >
                                    {video.yose_description}
                                  </Text>
                                  <LayoutBox fullWidth justify="center">
                                    <Button
                                      size="small"
                                      type="sub"
                                      shape="circle"
                                      width="311px"
                                      underlined
                                      onClick={() => goToYoseDetails(video._id)}
                                    >
                                      動画を見る
                                    </Button>
                                  </LayoutBox>
                                </LayoutBox>
                              </Sheet>
                            </LayoutBox>
                          </LayoutBox>
                        </LayoutBox>
                      ))}
                    </LayoutBox>
                  </div>
                </div>
                <SpButton
                  width="311px"
                  size="medium"
                  withArrowRight
                  onClick={goToYoseList}
                >
                  他の動画を見る
                </SpButton>
              </LayoutBox>
            </Sheet>
          </div>
        </Sheet>
      </div>
    </>
  );
};

export default SectionOnlineVaudeville;
