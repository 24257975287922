import React from "react";
import { useNavigate } from "react-router-dom";
import Text from "../components/Text/Text";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import HeaderUser from "../components/Header/HeaderUser";
import Sheet from "../components/Sheet/Sheet";
import Icon from "../components/Icon/Icon";
import SpButton from "../components/Button/SpButton";
import Title from "../components/Title/Title";
import { INVITATION, PATIENT } from "../constants/pagePaths";
import useUsa032Actions from "../hooks/pages/USA032/useUsa032DBActions";
import { useCheckErrorThrowError } from "../utils/checkError";
import PatientFooter from "../features/PatientFooter";
import { setPath } from "../hooks/common/usePath";
import InformationDialog from "../components/Dialog/InformationDialog";
import { setPaymentPeriodId } from "../hooks/common/usePaymentPeriod";
import {
  useNavigateWithBack,
  useNavigateWithUrl,
} from "../hooks/base/usePageTransitionCustom";

const USA032 = () => {
  const { result, error, updateApproval } = useUsa032Actions();
  const navigate = useNavigate();
  const navigateWithUrl = useNavigateWithUrl();
  const navigateWithBack = useNavigateWithBack(INVITATION.USA029);
  const [isOpen, setIsOpen] = React.useState(false);

  // エラー処理
  useCheckErrorThrowError([error]);

  const handleClickBack = () => {
    navigateWithBack();
  };

  const handleClickApproveReject = (
    parentPatientId: string | undefined,
    invitedPatientId: string | undefined,
    acceptance: boolean,
    paymentPlan: string | undefined,
  ) => {
    if (acceptance) {
      const plan = paymentPlan === "monthly" ? "1" : "2";
      setPaymentPeriodId(String(plan));
      setPath(INVITATION.USA032);
      navigateWithUrl(PATIENT.USA042);
    } else {
      void (async () => {
        const approvalData = await updateApproval(
          parentPatientId,
          invitedPatientId,
          acceptance,
        );
        if (approvalData.isError) return;

        navigate(INVITATION.USA035);
        window.location.reload(); // ヘッダーを無理やり更新
      })();
    }
  };

  // キャンセルダイアログclose
  const handleCloseCancelConfirm = () => {
    setIsOpen(false);
  };

  // キャンセルダイアログopen
  const handleCancelConfirm = () => {
    setIsOpen(true);
  };

  return (
    <div className="admin-area user-body-sp-full-height">
      <HeaderUser />
      <div className="admin-inner area-with-header-footer">
        <Title
          onClickBack={handleClickBack}
          padding="16px 108px 24px 108px"
          background="default"
          header={result?.name}
          borderBottom="primary"
          content={result?.email}
        />
        <Sheet padding="40px" className="util-py-40 util-sp-py-24">
          <LayoutBox direction="column" maxWidth="440px" width="440px">
            <Sheet
              type="card"
              padding="24px 16px 32px 16px"
              className="util-sp-pt-16"
            >
              <LayoutBox
                gap="3x"
                direction="column"
                align="center"
                fullWidth
                className="util-sp-flex--gap16"
              >
                <LayoutBox direction="column" gap="2x" align="center">
                  <Icon color="#D6A000" icon="warning" size="xl" />
                  <LayoutBox wrap justify="center">
                    <Text
                      bold
                      size="2xl"
                      color="neutralUserBlack"
                      lineHeight="150%"
                      textAlign="left"
                    >
                      招待コードが利用されました
                    </Text>
                  </LayoutBox>
                  <LayoutBox wrap justify="center">
                    <Text
                      size="xl"
                      color="neutralUserBlack"
                      lineHeight="150%"
                      textAlign="center"
                    >
                      ご自身が招待された方であることを確認して承認してください
                    </Text>
                  </LayoutBox>
                </LayoutBox>
                <SpButton
                  type="primary"
                  width="100%"
                  size="medium"
                  icon="check"
                  iconSize="large"
                  onClick={() =>
                    handleClickApproveReject(
                      result?.parent_patient_id,
                      result?.invited_patient_id,
                      true,
                      result?.payment_plan,
                    )
                  }
                >
                  承認する（お支払いへ）
                </SpButton>
                <SpButton
                  type="secondary"
                  width="100%"
                  size="medium"
                  icon="block"
                  iconSize="large"
                  onClick={handleCancelConfirm}
                >
                  否認する
                </SpButton>
              </LayoutBox>
            </Sheet>
          </LayoutBox>
        </Sheet>
      </div>
      <PatientFooter />
      {isOpen && (
        <InformationDialog
          open={isOpen}
          closeDialog={handleCloseCancelConfirm}
          title="招待を取り消してもよろしいですか？"
          isShowCloseButton={false}
          alertLevel="error"
          width="350px"
          footer={
            <>
              <LayoutBox justify="center" wrapReverse gap="2x">
                <SpButton
                  width="275px"
                  onClick={() =>
                    handleClickApproveReject(
                      result?.parent_patient_id,
                      result?.invited_patient_id,
                      false,
                      undefined,
                    )
                  }
                >
                  招待を取り消す
                </SpButton>
                <SpButton
                  type="secondary"
                  width="275px"
                  onClick={handleCloseCancelConfirm}
                >
                  キャンセル
                </SpButton>
              </LayoutBox>
            </>
          }
        >
          <LayoutBox align="center">
            <LayoutBox
              direction="column"
              gap="none"
              align="start"
              justify="start"
            >
              <Text size="xl">この操作は取り消せません。</Text>
            </LayoutBox>
          </LayoutBox>
        </InformationDialog>
      )}
    </div>
  );
};

export default USA032;
